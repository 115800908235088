import { useContextUser } from "contexts/user";
import { AlertConfiguration } from "models/Notification";
import React, { createContext, useContext, useState } from "react";
import {
  PlatformType,
  SocialChannel,
  NotificationType,
  CategoryType,
  BlockchainType,
} from "../../models/Enums";
import { useNotifications } from "../notifications";

export enum NewNotificationFlowStep {
  blockchainStep = "BlockchainStep",
  alertTypeStep = "AlertTypeStep",
  notificationConfigStep = "NotificationConfigStep",
}

/**
 * INewNotificationFlowState defines the New Notification flow state values and methods to interact with this state. It's responsible to activate the popup flow using isActive prop.
 */
export interface INewNotificationFlowState {
  isActive: boolean;
  status: string;
  currentStep: NewNotificationFlowStep | undefined;
  selectedNotificationBlockchain: BlockchainType | undefined;
  selectedCategoryType: CategoryType | undefined;
  selectedPlatformType: PlatformType | undefined;
  selectedNotificationType: NotificationType | undefined;
  selectedNotificationConfiguration: AlertConfiguration | undefined;
  selectedNotificationSocial: SocialChannel | undefined;
  creatingNotification: boolean;

  /**
   * Sets the state to its initial/default values.
   */
  beginCreateNewNotification(): void;
  /**
   * Cancels the notification flow and close the popup.
   */
  cancelCreateNewNotification(): void;

  /**
   * Go to the 2rt step of notification flow. Inherits the previous' and current step values if it's already defined. Define the next steps values to its initial/default values.
   */
  goToNotificationAlertTypes(blockchainType: BlockchainType): void;
  /**
   * Go to the 4rt step of notification flow. Inherits the previous' and current step values if it's already defined. Define the next steps values to its initial/default values.
   */
  /* 
  goToNotificationConfigurationStep(notificationType: NotificationType): void;
  goToNotificationSocialStepSkipConfig(
    notificationType: NotificationType
  ): void; */
  /**
   * Go to the 5th step of notification flow. Inherits the previous and current step values if it's already defined.
   */

  goToNotificationConfigs(
    categoryType: CategoryType,
    platformType: PlatformType,
    notificationType: NotificationType,
    blockchainType: BlockchainType
  ): void;

  setStatusNotification(status: string): void;

  /**
   * For each step it set the right previous step.
   */
  goBack(): void;
  /* goBackWithoutConfig(): void; */
  /**
   * The final step of the creation of the new notification. It saves the values and calls the adapter.
   */
  submitNewNotification(
    channels: SocialChannel[],
    alertConfiguration: AlertConfiguration
  ): Promise<void>;
}

/**
 * NewNotificationFlowContext is the interface implementation.
 * If not provided by the provider (parent-children context), it throught errors with a specific error message
 */
const NewNotificationFlowContext = createContext<INewNotificationFlowState>({
  isActive: false,
  status: "1",
  currentStep: undefined,
  selectedNotificationBlockchain: undefined,
  selectedCategoryType: undefined,
  selectedPlatformType: undefined,
  selectedNotificationType: undefined,
  selectedNotificationConfiguration: undefined,
  selectedNotificationSocial: undefined,
  creatingNotification: false,
  beginCreateNewNotification: () => {
    throw "Requesting component not nested inside NewNotificationFlowProvider.";
  },
  cancelCreateNewNotification: () => {
    throw "Requesting component not nested inside NewNotificationFlowProvider.";
  },
  submitNewNotification: () => {
    throw "Requesting component not nested inside NewNotificationFlowProvider.";
  },

  setStatusNotification: (status: string) => {
    throw "Requesting component not nested inside NewNotificationFlowProvider.";
  },
  goToNotificationAlertTypes: () => {
    throw "Requesting component not nested inside NewNotificationFlowProvider.";
  },
  goToNotificationConfigs: () => {
    throw "Requesting component not nested inside NewNotificationFlowProvider.";
  },
  goBack: () => {
    throw "Requesting component not nested inside NewNotificationFlowProvider.";
  },
  /*  goBackWithoutConfig: () => {
    throw "Requesting component not nested inside NewNotificationFlowProvider.";
  }, */
});

/* useContext is a native react method React  */
export const useNewNotificationFlow = () =>
  useContext(NewNotificationFlowContext);

/* Provide the context to children */
export const NewNotificationFlowContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { userData } = useContextUser();

  const [flowState, setFlowState] = useState<{
    isActive: boolean;
    status: string;
    currentStep: NewNotificationFlowStep | undefined;
    selectedNotificationBlockchain: BlockchainType | undefined;
    selectedCategoryType: CategoryType | undefined;
    selectedPlatformType: PlatformType | undefined;
    selectedNotificationType: NotificationType | undefined;
    selectedNotificationConfiguration: AlertConfiguration | undefined;
    selectedNotificationSocial: SocialChannel | undefined;
    creatingNotification: boolean;
  }>({
    isActive: false,
    status: "1",
    currentStep: undefined,
    selectedNotificationBlockchain: undefined,
    selectedCategoryType: undefined,
    selectedPlatformType: undefined,
    selectedNotificationType: undefined,
    selectedNotificationConfiguration: undefined,
    selectedNotificationSocial: undefined,
    creatingNotification: false,
  });
  const notifications = useNotifications();

  const beginCreateNewNotification = () => {
    setFlowState({
      isActive: true,
      status: "1",
      selectedNotificationBlockchain: undefined,
      currentStep: NewNotificationFlowStep.blockchainStep,
      selectedCategoryType: undefined,
      selectedPlatformType: undefined,
      selectedNotificationType: undefined,
      selectedNotificationConfiguration: undefined,
      selectedNotificationSocial: undefined,
      creatingNotification: false,
    });
  };

  const cancelCreateNewNotification = () => {
    setFlowState({
      isActive: false,
      status: "1",
      selectedNotificationBlockchain: undefined,
      currentStep: undefined,
      selectedCategoryType: undefined,
      selectedPlatformType: undefined,
      selectedNotificationType: undefined,
      selectedNotificationConfiguration: undefined,
      selectedNotificationSocial: undefined,
      creatingNotification: false,
    });
  };

  const setStatusNotification = (status: string) => {
    setFlowState({ ...flowState, status: status });
  };

  const submitNewNotification = async (
    channels: SocialChannel[],
    alertConfiguration: AlertConfiguration
  ) => {
    if (
      flowState.selectedNotificationType === undefined ||
      flowState.selectedPlatformType === undefined ||
      flowState.selectedCategoryType === undefined ||
      flowState.selectedNotificationBlockchain === undefined
    ) {
      throw "Blockchain, Category, platform and notification type not selected.";
    }

    setFlowState({
      ...flowState,
      creatingNotification: true,
    });

    if (userData) {
      await notifications.createNotification(
        userData?.userId,
        flowState.status,
        flowState.selectedNotificationBlockchain,
        flowState.selectedNotificationType,
        flowState.selectedCategoryType,
        flowState.selectedPlatformType,
        channels,
        alertConfiguration
      );
    }

    setFlowState({
      isActive: false,
      status: "1",
      selectedNotificationBlockchain: undefined,
      currentStep: undefined,
      selectedCategoryType: undefined,
      selectedPlatformType: undefined,
      selectedNotificationType: undefined,
      selectedNotificationConfiguration: undefined,
      selectedNotificationSocial: undefined,
      creatingNotification: false,
    });
  };

  /* 3th Step of New Notification Flow */
  const goToNotificationAlertTypes = (blockchain: BlockchainType) => {
    setFlowState({
      isActive: true,
      status: flowState.status,
      selectedNotificationBlockchain: blockchain,
      currentStep: NewNotificationFlowStep.alertTypeStep,
      selectedCategoryType: undefined,
      selectedPlatformType: undefined,
      selectedNotificationType: undefined,
      selectedNotificationConfiguration: undefined,
      selectedNotificationSocial: undefined,
      creatingNotification: false,
    });
  };
  /* 4th Step of New Notification Flow */
  const goToNotificationConfigs = (
    categoryType: CategoryType,
    platformType: PlatformType,
    notificationType: NotificationType,
    blockchain: BlockchainType
  ) => {
    setFlowState({
      isActive: true,
      status: flowState.status,
      selectedNotificationBlockchain: blockchain,
      currentStep: NewNotificationFlowStep.notificationConfigStep,
      selectedCategoryType: categoryType,
      selectedPlatformType: platformType,
      selectedNotificationType: notificationType,
      selectedNotificationConfiguration: undefined,
      selectedNotificationSocial: undefined,
      creatingNotification: false,
    });
  };

  const goBack = () => {
    let nextStep: NewNotificationFlowStep =
      NewNotificationFlowStep.alertTypeStep;

    if (NewNotificationFlowStep.alertTypeStep === flowState.currentStep) {
      nextStep = NewNotificationFlowStep.blockchainStep;
    }
    if (
      NewNotificationFlowStep.notificationConfigStep === flowState.currentStep
    ) {
      nextStep = NewNotificationFlowStep.alertTypeStep;
    }

    setFlowState({
      ...flowState,
      currentStep: nextStep,
    });
  };

  /* The contect calls the native method Provider, which provides the context to children.
    Everytime the state is changed, this change will be reflected to the right children components */
  return (
    <NewNotificationFlowContext.Provider
      value={{
        isActive: flowState.isActive,
        status: flowState.status,
        currentStep: flowState.currentStep,
        selectedNotificationBlockchain:
          flowState.selectedNotificationBlockchain,

        selectedCategoryType: flowState.selectedCategoryType,
        selectedPlatformType: flowState.selectedPlatformType,
        selectedNotificationType: flowState.selectedNotificationType,
        selectedNotificationConfiguration:
          flowState.selectedNotificationConfiguration,
        selectedNotificationSocial: flowState.selectedNotificationSocial,
        creatingNotification: flowState.creatingNotification,
        beginCreateNewNotification,
        cancelCreateNewNotification,
        submitNewNotification,
        setStatusNotification,
        goToNotificationAlertTypes,
        goToNotificationConfigs,
        goBack,
      }}
    >
      {children}
    </NewNotificationFlowContext.Provider>
  );
};
