import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectAlertTypes } from "components/notifications/notificationSteps/SelectAlertTypes";
import {
  BlockchainType,
  CategoryType,
  NotificationType,
  PlatformType,
} from "models/Enums";
import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from "components/Button";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotificationAlertTypeStep: React.FC<{
  handleCancel(): void;
  handleGoBack(): void;
  blockchain: BlockchainType;
  handleGoToNext(
    categoryType: CategoryType,
    platformType: PlatformType,
    notificationType: NotificationType,
    blockchain: BlockchainType
  ): void;
}> = ({ handleCancel, blockchain, handleGoToNext, handleGoBack }) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const [notificationSetup, setNotificationSetup] =
    useState<[CategoryType, PlatformType, NotificationType]>();

  const handleGoToNextClick = () => {
    notificationSetup &&
      handleGoToNext(
        notificationSetup[0] as CategoryType,
        notificationSetup[1] as PlatformType,
        notificationSetup[2] as NotificationType,
        blockchain as BlockchainType
      );
    return true;
  };

  const handleBack = () => {
    handleGoBack();
  };

  return (
    <>
      <div className="w-full">
        <SelectAlertTypes
          setNotificationSetup={setNotificationSetup}
          blockchainId={blockchain}
        />
        <div className="flex">
          <div className="flex-grow">
            <Button
              onClick={handleBack}
              type={ButtonType.cancel}
              size={ButtonSize.square}
            >
              <div>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            </Button>
          </div>

          <div className="flex justify-end">
            <Button
              onClick={handleCancel}
              type={ButtonType.cancel}
              size={ButtonSize.smallWide}
              justify={ButtonJustify.center}
            >
              {t("action_cancel", { ns: "common" })}
            </Button>
            <Button
              className="ml-2"
              disabled={!notificationSetup}
              type={ButtonType.primary}
              size={ButtonSize.smallWide}
              justify={ButtonJustify.center}
              onClick={handleGoToNextClick}
            >
              {t("action_next", { ns: "common" })}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationAlertTypeStep;
