import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import {
  BlockchainType,
  CategoryType,
  NotificationType,
  PlatformType,
} from "models/Enums";

export const SelectAlertTypes = ({
  blockchainId,
  setNotificationSetup,
}: {
  blockchainId: BlockchainType;
  setNotificationSetup: Dispatch<SetStateAction<any>>;
}) => {
  const newNotificationFlow = useNewNotificationFlow();
  const { categories, projects, types } = useContextBlockchainData();
  const { t } = useTranslation(["common", "enumerations"]);
  const [configsAlert, setConfigsAlert] = useState<
    [CategoryType, PlatformType, NotificationType] | []
  >([]);

  /**
   *  Get compatible projects by blockchain ID
   */
  const getProjectsByBlockchainId = () => {
    if (!blockchainId || !projects) return [];
    return projects.filter(
      (p) =>
        newNotificationFlow?.selectedNotificationBlockchain === p.chain_id ||
        p.chain_id === blockchainId
    );
  };

  /**
   *  Get Notification types by blockchain ID
   */
  const getTypesByBlockchainId = () => {
    if (!blockchainId) return;
    return types?.filter((p) => p.chain_id === blockchainId);
  };

  /**
   * For translation puproses
   * @param type
   * @param id
   * @returns
   */
  const getNameById = (
    type: typeof CategoryType | typeof PlatformType | typeof NotificationType,
    id: CategoryType | PlatformType | NotificationType
  ) => {
    id =
      (type === NotificationType && (id.slice(-4) as NotificationType)) || id;

    const nameCategory = Object.entries(type).find(([key, value]): string => {
      return value === id && value;
    })?.[0];
    const keyName =
      (type === CategoryType && "category_types") ||
      (type === PlatformType && "platform_types") ||
      (type === NotificationType && "notification_types");

    if (nameCategory !== undefined) {
      return t(`${keyName}.${nameCategory}`, {
        ns: "enumerations",
      });
    } else {
      return "";
    }
  };

  /**
   *   Set selected alert by its category, plaform and type
   */
  const handleAlertData = (dataArray: string) => {
    const data = dataArray?.split(",");
    setConfigsAlert([
      data[0] as CategoryType,
      data[1] as PlatformType,
      data[2] as NotificationType,
    ]);
  };

  useEffect(() => {
    if (newNotificationFlow.selectedNotificationType)
      setConfigsAlert([
        newNotificationFlow?.selectedCategoryType as CategoryType,
        newNotificationFlow.selectedPlatformType as PlatformType,
        newNotificationFlow?.selectedNotificationType as NotificationType,
      ]);
  }, [newNotificationFlow]);

  useEffect(() => {
    configsAlert.length && setNotificationSetup(configsAlert);
  }, [configsAlert]);

  return (
    <div className="my-12">
      <select
        multiple={false}
        onChange={(event) => handleAlertData(event?.target?.value)}
        className="focus:ring-0 selectTypes  focus:outline-none customScrollbar"
      >
        <option value="" selected disabled className="font-extrabold">
          {t("notifications.select_type", { ns: "common" })}
        </option>

        {categories?.map((c) => {
          const categoryProjets = getProjectsByBlockchainId()?.filter((p) =>
            p.category?.includes(c.category_id)
          );

          return (
            <optgroup
              className="relative"
              label={
                (!categoryProjets.length &&
                  `${getNameById(
                    CategoryType,
                    c?.category_id as CategoryType
                  )} - ${t("notifications.not_available_chain", {
                    ns: "common",
                  })}`) ||
                `${getNameById(CategoryType, c?.category_id as CategoryType)}`
              }
            >
              {categoryProjets.map((pc) => {
                const nameProject = getNameById(
                  PlatformType,
                  pc?.project_market_id as PlatformType
                );
                return (
                  <>
                    {nameProject !== "" && (
                      <option className="forceLeft font-bold " disabled>
                        {getNameById(
                          PlatformType,
                          pc?.project_market_id as PlatformType
                        )}
                      </option>
                    )}
                    {getTypesByBlockchainId()
                      ?.filter(
                        (f) => f.project_market_id === pc.project_market_id
                        //&& f.chain_id === blockchainId
                      )
                      .map((alert) => {
                        return (
                          !alert.only_BE && (
                            <>
                              <option
                                value={[
                                  alert?.category_id as CategoryType,
                                  alert?.project_market_id as PlatformType,
                                  alert?.notification_type_id as NotificationType,
                                ]}
                                className="pl-4 "
                                selected={
                                  newNotificationFlow?.selectedCategoryType ===
                                    alert.category_id &&
                                  newNotificationFlow?.selectedPlatformType ===
                                    alert.project_market_id &&
                                  newNotificationFlow?.selectedNotificationType ===
                                    alert.notification_type_id
                                }
                              >
                                &nbsp;&nbsp;&nbsp;
                                {getNameById(
                                  NotificationType,
                                  alert.notification_type_id as NotificationType
                                )}
                              </option>
                            </>
                          )
                        );
                      })}
                  </>
                );
              })}
            </optgroup>
          );
        })}
      </select>
      {configsAlert[2] && (
        <div className="pt-2 px-2 flex text-sm">
          <span>
            {configsAlert[0] && (
              <>
                <span className="block sm:inline ">
                  <b className="text-gold">Category: </b>
                  {getNameById(CategoryType, configsAlert[0] as CategoryType)}
                </span>
              </>
            )}

            {configsAlert[1] && (
              <>
                <span className="block sm:inline">
                  <b className="text-gold"> Platform: </b>
                  {getNameById(PlatformType, configsAlert[1] as PlatformType)}
                </span>
              </>
            )}
          </span>
        </div>
      )}
    </div>
  );
};
