import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { AlertConfiguration, ConfigurationParams } from "models/Notification";
import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from "components/Button";

import {
  GovernancePoll,
  WalletWatcher,
  ProfileChanges,
  NFTMonitor,
  LiquidStaking,
} from "../notificationTypes/index";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationType, PlatformType, SocialChannel } from "models/Enums";
import SocialChannels from "./SocialChannels";
import PriceAlert from "../notificationTypes/PriceAlert";
import LiquidityPool from "../notificationTypes/LiquidityPool";
import DomainChanges from "../notificationTypes/DomainChanges";
import DomainRegistration from "../notificationTypes/DomainRegistration";
import Staking from "../notificationTypes/Staking";

const ConfigurationStep: React.FC<{
  handleCancel(): void;
  handleGoToNext(
    channels: SocialChannel[],
    alertConfiguation: AlertConfiguration
  ): void;
  handleGoBack(): void;
  currentPlatform: PlatformType;
  currentType: NotificationType;
  isCreatingNotification: boolean;
}> = ({
  currentPlatform,
  currentType,
  handleCancel,
  handleGoToNext,
  handleGoBack,
  isCreatingNotification,
}) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { types, projects } = useContextBlockchainData();
  const newNotificationFlow = useNewNotificationFlow();
  const [disabledButton, setDisableButton] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<ConfigurationParams>({});
  const [socialChannels, setSocialChannels] = useState<SocialChannel[]>([]);

  const handleBack = () => {
    handleGoBack();
  };

  const handleGoToNextClick = () => {
    if (NotificationType.GovernancePoll === currentType.slice(-4)) {
      let govAddress = projects?.filter(
        (p) => p.project_market_id === currentPlatform
      )[0].gov_address;
      handleGoToNext(
        socialChannels,
        new AlertConfiguration({
          ...selectedValues,
          walletAddress: govAddress,
          mention: "",
        })
      );
    } else {
      handleGoToNext(
        socialChannels,
        new AlertConfiguration({ ...selectedValues })
      );
    }
  };

  useEffect(() => {
    setDisableButton(true);
  }, []);

  return (
    <>
      <div className="pb-3 w-full">
        {types
          ?.filter(
            ({ project_market_id, notification_type_id }) =>
              project_market_id === newNotificationFlow.selectedPlatformType &&
              notification_type_id ===
                newNotificationFlow.selectedNotificationType
          )
          .map((el) => {
            let typeNotification = el.notification_type_id.slice(-4);
            /* Governance Pool Alert */
            return (
              (NotificationType.GovernancePoll === typeNotification && (
                <div>
                  <GovernancePoll disabledBtn={setDisableButton} />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              /* Wallet Watcher  */
              (NotificationType.WalletWatcher === typeNotification && (
                <div>
                  <WalletWatcher
                    disabledBtn={setDisableButton}
                    setWallet={setSelectedValues}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              /* Profile Changes Alert */
              (NotificationType.ProfileChanges === typeNotification && (
                <div>
                  <ProfileChanges
                    disabledBtn={setDisableButton}
                    setValues={setSelectedValues}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              /*  NFT Monitor Alert */
              (NotificationType.NftMonitor === typeNotification && (
                <div>
                  <NFTMonitor
                    disabledBtn={setDisableButton}
                    setValues={setSelectedValues}
                    selectedValues={selectedValues}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              (NotificationType.PriceAlert === typeNotification && (
                <div>
                  <PriceAlert
                    setValues={setSelectedValues}
                    disabledBtn={setDisableButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              (NotificationType.LiquidityPool === typeNotification && (
                <div>
                  <LiquidityPool
                    setValues={setSelectedValues}
                    disabledBtn={setDisableButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              /* Domain Registration Alert */
              (NotificationType.DomainRegistration === typeNotification && (
                <div>
                  <DomainRegistration
                    disabledBtn={setDisableButton}
                    setValues={setSelectedValues}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              (NotificationType.DomainChanges === typeNotification && (
                <div>
                  <DomainChanges
                    setValues={setSelectedValues}
                    disabledBtn={setDisableButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              (NotificationType.LiquidityPool === typeNotification && (
                <>
                  <LiquidityPool
                    setValues={setSelectedValues}
                    disabledBtn={setDisableButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </>
              )) ||
              (NotificationType.LiquidStaking === typeNotification && (
                <>
                  <LiquidStaking
                    setValues={setSelectedValues}
                    disabledBtn={setDisableButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </>
              )) ||
              (NotificationType.Staking === typeNotification && (
                <>
                  <Staking
                    setValues={setSelectedValues}
                    disabledBtn={setDisableButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </>
              ))
            );
          })}
      </div>
      <div className="flex justify-between w-full justify-center sm:pt-2 pt-0">
        <div className="flex justify-between w-full justify-center sm:pt-2 pt-0">
          <div>
            <Button
              onClick={handleBack}
              type={ButtonType.cancel}
              size={ButtonSize.square}
            >
              <div>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              {/* after to add on hover in icon btn, show this information in desktop     */}
              <span className="text-sm mx-1 hidden">Back</span>
            </Button>
          </div>
          <div>
            <div className="flex">
              <Button
                onClick={handleCancel}
                type={ButtonType.cancel}
                size={ButtonSize.smallWide}
                justify={ButtonJustify.center}
              >
                {t("action_cancel", { ns: "common" })}
              </Button>
              {isCreatingNotification && (
                <>
                  <Button
                    onClick={() => {
                      handleGoToNextClick();
                    }}
                    className=" ml-2"
                    type={ButtonType.primary}
                    size={ButtonSize.smallWide}
                    justify={ButtonJustify.center}
                    loading={true}
                    disabled={disabledButton}
                    loadingTxt={t("loading_adding", { ns: "common" })}
                  >
                    <span className="sm:block hidden">
                      {t("action_add_notification", { ns: "common" })}
                    </span>
                    <span className="sm:hidden block">
                      {t("action_add_notification_mobile", { ns: "common" })}
                    </span>
                  </Button>
                </>
              )}

              {!isCreatingNotification && (
                <>
                  <Button
                    onClick={() => {
                      handleGoToNextClick();
                    }}
                    className="ml-2"
                    type={ButtonType.primary}
                    size={ButtonSize.smallWide}
                    justify={ButtonJustify.center}
                    disabled={disabledButton}
                  >
                    <span className="sm:block hidden">
                      {t("action_add_notification", { ns: "common" })}
                    </span>
                    <span className="sm:hidden block">
                      {t("action_add_notification_mobile", { ns: "common" })}
                    </span>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigurationStep;
