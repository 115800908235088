import {
  PlatformType,
  SocialChannel,
  NotificationType,
  CategoryType,
  BlockchainType,
} from "./Enums";
import { LiquidStakingFlags, Nft, PublicId } from "./Interfaces";

class Notification {
  notificationId: string;
  userId: string;
  status: string;
  chainId: BlockchainType;
  categoryId: CategoryType;
  notificationTypeId: NotificationType;
  projectId: PlatformType | undefined;
  alertConfiguration?: AlertConfiguration;
  socialChannels: SocialChannel[];
  constructor(
    notificationId: string,
    userId: string,
    status: string,
    chainId: BlockchainType,
    category: CategoryType,
    notificationTypeId: NotificationType,
    platform: PlatformType | undefined,
    socialChannels: SocialChannel[],
    alertConfiguration?: AlertConfiguration
  ) {
    this.notificationId = notificationId;
    this.userId = userId;
    this.status = status;
    this.chainId = chainId;
    this.categoryId = category;
    this.notificationTypeId = notificationTypeId;
    this.projectId = platform;
    this.alertConfiguration = alertConfiguration;
    this.socialChannels = socialChannels || [];
  }
}

/* export class AlertConfiguration {
  pair?: string;
  type?: string;
  value?: number;
  wallet?: string;


  constructor(pair?: string, type?: string, value?: number, wallet?: string) {
    this.pair = pair;
    this.type = type;
    this.value = value;
    this.wallet = wallet;
  }
} */

export interface ConfigurationParams {
  pair?: string[] | string;
  token?: string;
  contract?: string; // assets
  withdrawals?: boolean;
  deposits?: boolean;
  relative?: boolean;
  type?: string;
  value?: number;
  wallet?: string;
  scope?: string;
  walletAddress?: string;
  validatorAddress?: string[];
  publicId?: PublicId;
  monitorOptions?: string[];
  crypto1?: string;
  crypto2?: string;
  inverted?: boolean;
  nft?: Nft[];
  mention?: string;
  regex?: string;
  tokenContract?: string;
  submitBatch?: LiquidStakingFlags; //para notifs da webapp, value vem sempre 99999999 e type "". Apenas bot pode definir valores
  queueUnbond?: LiquidStakingFlags;
  finishUnbond?: LiquidStakingFlags; //para notifs da webapp, value vem sempre 99999999 e type "". Apenas bot pode definir valores
  stakes?: LiquidStakingFlags;
  delegate?: LiquidStakingFlags;
  undelegate?: LiquidStakingFlags;
  redelegate?: LiquidStakingFlags;
  withdrawRewards?: LiquidStakingFlags;
  to?: string;
  from?: string;
}

export class AlertConfiguration {
  params?: ConfigurationParams;

  constructor(params?: ConfigurationParams) {
    this.params = params;
  }
}
export default Notification;
