import React from "react";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { BlockchainType } from "models/Enums";
import { useTranslation } from "react-i18next";

/*
 TODO: show loading animation while image is loading
*/
const BlockchainImage: React.FC<{
  blockchainId: BlockchainType;
  imageWidth?: number;
  imageHeight?: number;
}> = ({ blockchainId, imageWidth = 50, imageHeight = 50 }) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const nameChain = Object.entries(BlockchainType).find(
    ([key, value]) => value === blockchainId && key
  );

  let blockchainIcon;
  switch (blockchainId) {
    case BlockchainType.Terrav2:
      blockchainIcon =
        "https://assets.hermesprotocol.io/img/projects/Terra.png";
      break;
    case BlockchainType.TerraClassic:
      blockchainIcon =
        "https://assets.hermesprotocol.io/img/projects/TerraClassic.png";
      break;
  }

  return (
    <img
      src={blockchainIcon}
      width={imageWidth}
      height={imageHeight}
      className={"mx-1"}
      alt={
        nameChain &&
        t(`blockchains_types_names.${nameChain[0]}`, {
          ns: "enumerations",
        })
      }
    />
  );
};
export default BlockchainImage;
