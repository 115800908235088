import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyButton } from "components/CopyButton";
import { UserNotification } from "models/Interfaces";
import { getBlockchainForAssets } from "models/utils/blockchainTranslations";
import { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useTranslation } from "react-i18next";

const LiquidityPoolCard: React.FC<{
  copyToClipboard: Function;
  copy: boolean;
  notification: UserNotification;
  getTypeDesc: Function;
  isFlipped: boolean;
}> = ({ copyToClipboard, copy, notification, getTypeDesc, isFlipped }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [token1, setToken1] = useState<null | string>(null);
  const [token2, setToken2] = useState<null | string>(null);
  const [image1, setImage1] = useState<null | string>(null);
  const [image2, setImage2] = useState<null | string>(null);
  const { t } = useTranslation(["common", "enumerations", ""]);
  let blockchain: string | null;

  useEffect(() => {
    setIsLoading(true);
    blockchain = getBlockchainForAssets(notification?.chainId);
    const getTokens = async () => {
      let tokensArr;
      try {
        const url = `${process.env.REACT_APP_ASSETS_REPO}${process.env.REACT_APP_ASSETS_CHAINS}${blockchain}/${process.env.REACT_APP_ASSETS_CONTRACTS}`;
        const connection = await fetch(url, {
          method: "GET",
        });
        const contractsData = await connection.json();
        if (contractsData) {
          const contract = contractsData[notification.pair[0]];
          tokensArr = contract?.asset_infos.map((e: any) => {
            let layer1 = e.token || e.native_token;
            return layer1.contract_addr || layer1.denom;
          });
        }
      } catch (error) {
        console.log(error);
      }
      try {
        const url = `${process.env.REACT_APP_ASSETS_REPO}${process.env.REACT_APP_ASSETS_CHAINS}${blockchain}/${process.env.REACT_APP_ASSETS_TOKENS}`;
        const connection = await fetch(url, {
          method: "GET",
        });
        const tokensData = await connection.json();
        setToken1(tokensData[tokensArr[1]]?.symbol);
        setToken2(tokensData[tokensArr[0]]?.symbol);
        setImage1(tokensData[tokensArr[1]]?.icon);
        setImage2(tokensData[tokensArr[0]]?.icon);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    getTokens();
  }, [notification]);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="h-1/6">
        {getTypeDesc(notification.notificationTypeId.slice(-4))}
      </h4>
      <div className="flex items-center h-1/6">
        {isLoading && <div className="loading-ring gray-dark"></div>}

        {!isLoading && (
          <>
            <h6 className="font-medium text-black-transparent50">
              {`${
                notification?.relative
                  ? t(`notifications.relative`, { ns: "common" })
                  : t(`notifications.absolute`, { ns: "common" })
              }`}
              /
              {`${t(`alert_configuration_settings.${notification.type}`, {
                ns: "common",
              })} ${notification.value}`}
              {notification.relative !== false && " %"}
            </h6>
          </>
        )}
      </div>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="vertical"
        cardStyles={{
          front: { height: "94.35px" },
          back: { height: "94.35px" },
        }}
      >
        <div className="flex items-center justify-start transition h-4/6">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && (
            <>
              <div className="flex items-center justify-start">
                <div className="flex items-center justify-start">
                  {image1 && token1 && (
                    <img
                      src={image1}
                      alt={token1}
                      className="w-5 h-5 rounded"
                    />
                  )}
                  <span className="ml-2 mr-2 text-sm">{`${token1}`}</span>
                </div>
                <div className="flex justify-center">
                  <FontAwesomeIcon
                    icon={faArrowsAltH}
                    className="text-3xl ml-2 mr-2 text-gold"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <span className="ml-2 mr-2 text-sm">{token2}</span>
                  {image2 && token2 && (
                    <img
                      src={image2}
                      alt={token2}
                      className="w-5 h-5 rounded"
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex items-center justify-between transition h-full">
          <ul className="list-disc ml-7 text-sm">
            {notification.withdrawals && (
              <li>{t("notifications.withdrawals", { ns: "common" })}</li>
            )}
            {notification.deposits && (
              <li>{t("notifications.deposits", { ns: "common" })}</li>
            )}
          </ul>
        </div>
      </ReactCardFlip>
    </div>
  );
};
export default LiquidityPoolCard;
