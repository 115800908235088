import "dotenv/config";

import crypto from "crypto";

const returnBase64String = (str: Buffer) => {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};
const returSha256CodifiedString = (buffer: crypto.BinaryLike) => {
  return crypto.createHash("sha256").update(buffer).digest();
};

let state = returnBase64String(crypto.randomBytes(32));
const code_verifier = returnBase64String(crypto.randomBytes(94));
const code_challenge = returnBase64String(
  returSha256CodifiedString(code_verifier)
);

export const getSocialAuthorizeUrl = (social: string) => {
  sessionStorage.setItem("state_check", JSON.stringify(state));
  const reqData = new URLSearchParams();
  let url: string;

  switch (social) {
    case "discord":
      reqData.append("client_id", process.env.REACT_APP_DISCORD_ID!);
      reqData.append(
        "redirect_uri",
        process.env.REACT_APP_DISCORD_REDIRECT_URL!
      );
      reqData.append("response_type", "code");
      reqData.append("scope", "identify");
      reqData.append("state", state);
      url = "https://discord.com/api/oauth2/authorize?" + reqData;
      return url;

    case "twitter":
      sessionStorage.setItem(
        "twitter_code_verifier",
        JSON.stringify(code_verifier)
      );
      sessionStorage.setItem(
        "twitter_code_challenge",
        JSON.stringify(code_challenge)
      );
      reqData.append("response_type", "code");
      reqData.append("client_id", "d2ZJcEsyeTlsSWZrTlBucWJxcEE6MTpjaQ");
      reqData.append(
        "redirect_uri",
        process.env.TWITTER_REDIRECT_URL ||
          "https://app-stg.hermesprotocol.io/oauth/twitter/redirect" /* "http://localhost:3000/oauth/twitter/redirect" */
      );
      reqData.append("scope", "users.read tweet.read offline.access");
      reqData.append("state", state);
      reqData.append("code_challenge", code_challenge);
      reqData.append("code_challenge_method", "S256");
      url = "https://twitter.com/i/oauth2/authorize?" + reqData;
      return url;

    case "telegram":
      url = "https://t.me/hermesfortelegrambot?start=login";
      return url;
  }
};
