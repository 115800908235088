import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContextUser } from "contexts/user";
import {
  AvailableTlds,
  BlockchainType,
  DomainOptions,
  ScopeNotification,
} from "models/Enums";
import { getDomain, getDomainExpiration } from "../../../models/utils/terra";
import { Domain, UserNotification } from "models/Interfaces";
import keccak256 from "keccak256";
import { Balloon, BalloonType } from "components/Balloon";
import { useNewNotificationFlow } from "contexts/new-notification-flow";

const DomainRegistration = ({
  disabledBtn,
  setValues,
  notification,
}: {
  disabledBtn?: Dispatch<SetStateAction<any>>;
  setValues: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation("common, enumerations");
  const { userData } = useContextUser();
  const { selectedNotificationBlockchain } = useNewNotificationFlow();

  const [scope, setScope] = useState<string | "">(
    notification ? notification.scope : ""
  );

  const [showOptions, setShowOptions] = useState<boolean>(
    notification ? true : false
  );
  const [userDomains, setUserDomains] = useState<Domain[] | []>([]);
  const [domain, setDomain] = useState<string | "">(
    notification ? notification.domain.name : ""
  );
  const [domainExpiration, setDomainExpiration] = useState(0);
  const [options, setOptions] = useState({
    renewed: notification ? notification.options.renewed.flag : false,
    monthbefore: notification ? notification.options.monthbefore.flag : false,
    weekbefore: notification ? notification.options.weekbefore.flag : false,
    h48before: notification ? notification.options.h48before.flag : false,
    h24before: notification ? notification.options.h24before.flag : false,
    expired: notification ? notification.options.expired.flag : false,
  });
  const [error, setError] = useState<boolean>(false);

  /**
   * Set initial fields values.
   * Called when component is loading and on every validator select change to reset the other fields.
   */
  const resetFields = () => {
    disabledBtn && disabledBtn(true);
    setScope("");
    setDomain("");
    setShowOptions(false);
  };

  /**
   * Handle Scope Changed
   * @param e onChange event
   */
  const handleScopeChanged = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    resetFields();
    setScope(e.target?.value);
    setShowOptions(true);
    if (e.target?.value === "") setShowOptions(false);
    else {
      e.target?.value === ScopeNotification.myDomain
        ? userDomains.length === 1 && setDomain(userDomains![0].name)
        : setDomain("");
    }
  };

  const handleDomainChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e?.target.value);
    if (e?.target.value !== "") {
      (async () => {
        const domainExists = await getDomainExpiration(
          e?.target.value,
          selectedNotificationBlockchain!
        );

        domainExists === undefined ? setError(true) : setError(false);
      })();
    } else setError(false);
  };

  const getDomainFromWallet = () => {
    let wallets = userData?.wallets;

    wallets
      ?.filter((w) =>
        selectedNotificationBlockchain === BlockchainType.Terrav2 ||
        selectedNotificationBlockchain === BlockchainType.TerraClassic
          ? w.chainId === BlockchainType.Terrav2 ||
            w.chainId === BlockchainType.TerraClassic
          : w.chainId === selectedNotificationBlockchain
      )
      .forEach(async (w) => {
        const domainExists = await getDomain(
          w.walletAddress,
          selectedNotificationBlockchain!
        );
        if (domainExists) {
          setUserDomains([...userDomains, domainExists]);
        }
      });
  };

  const getExpirationFromDomain = async () => {
    const domainExists = await getDomainExpiration(
      domain,
      selectedNotificationBlockchain!
    );

    if (domainExists) {
      setDomainExpiration(domainExists.expires_at);
      return true;
    }
    return false;
  };

  const handleChangeOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptions({ ...options, [e.target.name]: e.target.checked });
  };

  const saveValues = async () => {
    setValues({
      contract:
        "terra16a6qkmxpqzeyez8gh3w7qhrk7x3xe3arlv9nwfg944y8vzg9smrqntark3",
      scope,
      options: {
        h24before: {
          flag: options.h24before,
          timestamp: options.h24before ? domainExpiration - 86400 : 99999999,
        },
        h48before: {
          flag: options.h48before,
          timestamp: options.h48before ? domainExpiration - 172800 : 99999999,
        },
        weekbefore: {
          flag: options.weekbefore,
          timestamp: options.weekbefore ? domainExpiration - 604800 : 99999999,
        },
        monthbefore: {
          flag: options.monthbefore,
          timestamp: options.monthbefore
            ? domainExpiration - 2629743
            : 99999999,
        },
        expired: {
          flag: options.expired,
          timestamp: options.expired ? domainExpiration : 99999999,
        },
        renewed: { flag: options.renewed },
      },
      domain: {
        name: domain,
        tld: AvailableTlds.luna,
        tokenId: keccak256(domain).toString("hex"),
      },
    });
  };

  useEffect(() => {
    if (!notification) {
      getDomainFromWallet();
    }
  }, []);

  useEffect(() => {
    if (userDomains.length === 1) {
      setDomain(userDomains[0].name);
    }
  }, [userDomains]);

  useEffect(() => {
    const hasOptions = Object.values(options).filter((o) => o);
    (async () => await getExpirationFromDomain())(); // Call getExpirationFromDomain when domain is set
    if (
      domainExpiration === 0 ||
      hasOptions.length === 0 ||
      scope === "" ||
      domain === ""
    ) {
      disabledBtn && disabledBtn(true);
    } else {
      disabledBtn && disabledBtn(false);
      saveValues();
    }
  }, [options, domain, scope, error]);

  return (
    <>
      <div className="w-full">
        {!notification && (
          <div className="pb-4 border-b border-black-line w-full ">
            <h3>{t("notifications.domain_registration", { ns: "common" })}</h3>
          </div>
        )}
        {/* Scope selectbox */}
        <div className="mt-6">
          <select
            multiple={false}
            value={scope}
            onChange={handleScopeChanged}
            className="focus:ring-0 focus:outline-none "
            disabled={notification?.notificationId !== undefined}
          >
            <option value="">
              {t(`notifications.select_scope`, { ns: "common" })}
            </option>
            {Object.values(ScopeNotification)
              .filter(
                (value) =>
                  value === ScopeNotification.myDomain ||
                  value === ScopeNotification.otherDomain
              )
              .map((el) => {
                return (
                  <option value={el} key={Math.random() * 0.5}>
                    {t(`scope_notifications.domain.${el}`, {
                      ns: "enumerations",
                    })}
                  </option>
                );
              })}
          </select>
        </div>
        {/* Domain selectbox for my domains */}
        {((notification?.notificationId !== undefined &&
          notification?.scope === ScopeNotification.myDomain) ||
          scope === ScopeNotification.myDomain) && (
          <div className="mt-6">
            <>
              <p className="text-sm mb-1">
                {t("notifications.domain", {
                  ns: "common",
                })}
              </p>
              <select
                multiple={false}
                onChange={(e) => setDomain(e.target.value)}
                value={domain}
                className={`focus:border-0 myWallet focus:ring-0 focus:outline-none`}
                disabled={
                  userDomains.length <= 1 ||
                  notification?.notificationId !== undefined
                }
              >
                <>
                  {userDomains.length === 0 && (
                    <option value="">
                      {t(`notifications.no_domain`, { ns: "common" })}
                    </option>
                  )}
                  {userDomains.length > 1 && (
                    <option value="">
                      {t(`notifications.select_domain`, { ns: "common" })}
                    </option>
                  )}
                  {userDomains.map((d) => {
                    return (
                      <option value={d.name}>
                        {d.name +
                          "." +
                          t(`notifications.luna`, { ns: "common" })}
                      </option>
                    );
                  })}
                </>
              </select>
            </>
          </div>
        )}
        {/* Other domain selectbox */}
        {((notification?.notificationId !== undefined &&
          notification?.scope === ScopeNotification.otherDomain) ||
          scope === ScopeNotification.otherDomain) && (
          <div className="mt-6">
            <>
              <p className="text-sm mb-1">
                {t("notifications.domain", {
                  ns: "common",
                })}
              </p>
              <div className="flex space-x-4  items-center">
                <div
                  className={`flex flex-grow  border-none overflow-hidden  `}
                >
                  <input
                    type={`text`}
                    value={domain}
                    onChange={handleDomainChanged}
                    placeholder={t("notifications.domain_name_wo_regex", {
                      ns: "common",
                    })}
                    className={`focus:ring-0 mr-2 focus:opacity-100 w-full text-sm focus:border-gray-extralight90medium focus:outline-none rounded p-0 px-3 border-gray-extralight90medium text-gray-medium`}
                    disabled={notification?.notificationId !== undefined}
                  />

                  <select
                    multiple={false}
                    className={`focus:ring-0 focus:opacity-100 focus:border-gray-extralight90medium sm:w-4/12  focus:outline-none px-3 rounded h-10 p-0 border-gray-extralight90medium text-gray-medium  `}
                    disabled={true}
                  >
                    <option>
                      {"." + t(`notifications.luna`, { ns: "common" })}
                    </option>
                  </select>
                </div>
              </div>
              {notification?.notificationId === undefined && error && (
                <small className="px-1 text-red">
                  {t("notifications.invalid_domain", { ns: "common" })}
                </small>
              )}
            </>
          </div>
        )}
        {showOptions && (
          <>
            <h6 className="text-medium mt-8 mb-2">
              {t("notifications.domain_options", { ns: "common" })}
            </h6>
            <div className="text-sm my-7 grid lg:grid-cols-2 sm:mx-6 mx-2">
              {Object.entries(options).map(([v, k]) => (
                <label
                  htmlFor={v}
                  className="cursor-pointer text-sm font-normal mb-3"
                >
                  <input
                    className="mx-2 focus:outline-none focus:ring-0 text-gold border-gold checked:border-0 focus:ring-transparent cursor-pointer transition-colors "
                    type="checkbox"
                    name={v}
                    id={v}
                    onChange={handleChangeOptions}
                    checked={k}
                  />
                  {t(`domain_options.${v}`, {
                    ns: "enumerations",
                  })}
                  {DomainOptions.uponRenewal !== v && (
                    <span className="text-gold ml-0.5 inline-block">!</span>
                  )}
                </label>
              ))}
            </div>
            <div className="w-full">
              <div className="py-4 px-2 shadow-sm bg-gold10 rounded flex ">
                <div className="flex grow-0 text-white relative items-start justify-start -ml-2 mr-2">
                  <Balloon
                    disabled={true}
                    type={BalloonType.info}
                    id="connect-info"
                    noFlex={true}
                    noEffectHover={true}
                  >
                    <span>!</span>
                  </Balloon>
                </div>
                <div className="text-sm">
                  <div className="mt-1 mb-2">
                    {t("notifications.disclaimer", {
                      ns: "common",
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DomainRegistration;
