import classNames from "classnames";
import React from "react";

const RightSection: React.FC<{
  children: React.ReactNode;
  isCondensed: boolean;
}> = ({ children, isCondensed }) => (
  <div
    className={classNames(
      "overflow-x-hidden overflow-y-auto flex-col flex py-5 w-full min-h-screen",
      {
        "ml-0 lg:ml-24": isCondensed,
      },
      {
        "ml-0 lg:ml-72": !isCondensed,
      }
    )}
  >
    {children}
  </div>
);

export default RightSection;
