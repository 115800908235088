import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { useContextUser } from "../../contexts/user/index";
import { getNotifications } from "adapters/notifications-service";
import {
  PlatformType,
  SocialChannel,
  NotificationType,
  CategoryType,
  BlockchainType,
} from "models/Enums";
import Notification, {
  AlertConfiguration,
  ConfigurationParams,
} from "models/Notification";
import { UserData } from "../../models/Interfaces";
import { instanceOfUserData } from "utils/instanceOf";

export interface INotificationsState {
  activeNotifications: Notification[];
  isLoadingNotifications: boolean;
  isCreatingNotification: boolean;
  isEditingNotification: boolean;
  isRemovingNotification: boolean;
  loadNotifications(): Promise<void>;
  createNotification(
    userId: string,
    status: string,
    chainId: BlockchainType,
    type: NotificationType,
    category: CategoryType,
    platform: PlatformType,
    socialChannels: SocialChannel[],
    alertConfiguration: AlertConfiguration
  ): Promise<UserData | undefined>;
  editNotification(
    notificationId: string,
    userId: string,
    status: string,
    socialChannels: SocialChannel[],
    alertConfiguration: ConfigurationParams
  ): Promise<void>;
  removeNotification(id: string): Promise<void>;
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
}

const NotificationsContext = createContext<INotificationsState>({
  activeNotifications: [],
  isLoadingNotifications: false,
  isCreatingNotification: false,
  isEditingNotification: false,
  isRemovingNotification: false,
  loadNotifications: () => {
    throw "Requesting component not nested inside NotificationsProvider";
  },
  createNotification: () => {
    throw "Requesting component not nested inside NotificationsProvider";
  },
  editNotification: () => {
    throw "Requesting component not nested inside NotificationsProvider";
  },
  removeNotification: () => {
    throw "Requesting component not nested inside NotificationsProvider";
  },
  error: null,
  setError: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
});

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeNotifications, setActiveNotifications] = useState<
    Notification[]
  >([]);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [isCreatingNotification, setIsCreatingNotification] = useState(false);
  const [isEditingNotification, setIsEditingNotification] = useState(false);
  const [isRemovingNotification, setIsRemovingNotification] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { userData, setUser } = useContextUser();

  //! the function bellow is not being used
  const loadNotifications = async () => {
    setIsLoadingNotifications(true);
    setActiveNotifications(await getNotifications());
    setIsLoadingNotifications(false);
  };

  const createNotification = async (
    userId: string,
    status: string,
    chainId: BlockchainType,
    type: NotificationType,
    category: CategoryType,
    platform: PlatformType,
    socialChannels: SocialChannel[],
    alertConfiguration: AlertConfiguration
  ): Promise<UserData | undefined> => {
    setIsCreatingNotification(true);

    let discordId = "",
      telegramId = "",
      twitterId = "";

    socialChannels?.map((s) => {
      if (s === SocialChannel.Discord) {
        discordId =
          userData?.socials?.filter(
            (s) => s.type === SocialChannel.Discord.toLowerCase()
          )[0].id || "";
      } /* else if (s === SocialChannel.Telegram) {
        telegramId =
          userData?.socials?.filter(
            (s) => s.type === SocialChannel.Telegram.toLowerCase()
          )[0].id || "";
      } */
    });

    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/notifications`,
        {
          method: "POST",
          body: JSON.stringify({
            userId,
            status: socialChannels.length > 0 ? "1" : "0",
            chainId,
            categoryId: category,
            projectId: platform,
            notificationTypeId: type,
            publicId: {
              groupId: "",
              channelId: "",
              type: "",
            },
            discordId,
            twitterId,
            telegramId,
            ...alertConfiguration.params,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setUser(dataFromApi.data);
      } else {
        setError(dataFromApi.message);
      }
      return dataFromApi.data;
    } catch (error) {
      console.error(error);
    }
    setIsCreatingNotification(false);
  };

  const editNotification = async (
    notificationId: string,
    userId: string,
    status: string,
    socialChannels: SocialChannel[],
    alertConfiguration: ConfigurationParams
  ): Promise<void> => {
    setIsEditingNotification(true);

    let discordId = "",
      telegramId = "",
      twitterId = "";

    socialChannels?.map((s) => {
      if (s === SocialChannel.Discord) {
        discordId =
          userData?.socials?.filter(
            (s) => s.type === SocialChannel.Discord.toLowerCase()
          )[0].id || "";
      } /* else if (s === SocialChannel.Telegram) {
        telegramId =
          userData?.socials?.filter(
            (s) => s.type === SocialChannel.Telegram.toLowerCase()
          )[0].id || "";
      } */
    });

    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/notifications/${notificationId}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            userId,
            status,
            discordId,
            twitterId,
            telegramId,
            ...alertConfiguration,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setUser(dataFromApi.data);
      } else {
        setError(dataFromApi.message);
      }
      return dataFromApi.data;
    } catch (error) {
      console.error(error);
    }
    setIsEditingNotification(false);
  };

  const removeNotification = async (id: string): Promise<void> => {
    setIsRemovingNotification(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/notifications/${id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            userId: userData?.userId,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setUser(dataFromApi.data);
      } else {
        setError(dataFromApi.message);
      }
      return dataFromApi.data;
    } catch (error) {
      console.error(error);
    }
    setIsRemovingNotification(false);
  };

  return (
    <NotificationsContext.Provider
      value={{
        activeNotifications: activeNotifications,
        isLoadingNotifications: isLoadingNotifications,
        isCreatingNotification: isCreatingNotification,
        isEditingNotification: isEditingNotification,
        isRemovingNotification: isRemovingNotification,
        loadNotifications: loadNotifications,
        createNotification: createNotification,
        editNotification: editNotification,
        removeNotification: removeNotification,
        error: error,
        setError,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
