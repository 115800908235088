import { CopyButton } from "components/CopyButton";
import { UserNotification } from "models/Interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const NewDomainCard: React.FC<{
  notification: UserNotification;
  getTypeDesc: Function;
  copyToClipboard: Function;
  copy: boolean;
}> = ({ notification, getTypeDesc, copy, copyToClipboard }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="mb-2 h-1/6">
        {getTypeDesc(notification.notificationTypeId.slice(-4))}
      </h4>
      <div className="flex items-center transition h-4/6">
        <h6 className="font-medium text-black-transparent50">
          {notification?.regex}
        </h6>
        <CopyButton
          hoverText={t("notifications.copy_address", "common")}
          clickText={t("notifications.copied", "common")}
          isCopying={copy}
          onCopy={copyToClipboard}

          copiedElement={
            notification.domain.name + "." + notification.domain.tld
          }


        />
        {/* to delete if not needed */}
        <span className="text-sm">
          {isLoading && <div className="loading-ring gray-dark"></div>}
        </span>
      </div>
    </div>
  );
};

export default NewDomainCard;
