import { NetworkInfo, WalletProvider } from "@terra-money/wallet-provider";
import { Suspense, useState } from "react";
import ReactDOM from "react-dom";
import "./style.css";
import "./slick-theme.css";
import "./i18n";
import { UserSettingPage } from "views/UserSettings";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "./views/Login";
import { SignInPage } from "./views/SignIn";
import { HistoryPage } from "./views/History";
import { HandleSocials } from "./utils/HandleSocials";
import { NotFoundErrorPage } from "./views/NotFoundErrorPage";
import { NotificationsPage } from "./views/Notifications";
import { SettingsPage } from "./views/Settings";
import Loader, { LoaderType } from "components/Loader";
import { useContextUser, UserProvider } from "contexts/user";
import { BlockchainDataProvider } from "contexts/blockchain-data";

const mainnet: NetworkInfo = {
  name: "mainnet",
  chainID: "columbus-5",
  lcd: "https://lcd.terra.dev",
  walletconnectID: 1,
};

const testnet: NetworkInfo = {
  name: "testnet",
  chainID: "bombay-12",
  lcd: "https://bombay-lcd.terra.dev",
  walletconnectID: 0,
};

const walletConnectChainIds: Record<number, NetworkInfo> = {
  0: testnet,
  1: mainnet,
};

function App() {
  /* Global variables that hold the complete user data. When no user is logged in, userData is null, thus user can't access private data.
  Together with the userData, on login and signup, API sets a cookie with auth token, which must be sent with every request, to validate the user. */

  const [error, setError] = useState<string | null>(null);
  const { userData, isLoggedIn } = useContextUser();

  return (
    <Suspense fallback="">
      <section className="pb-0 antialiased overflow-x-hidden">
        <div className="flex min-h-full w-screen items-start">
          {/* Embracing all routes with a validation of the auth global variable allows us to await to check user before the page loads and assigning correct auths to user */}
          {isLoggedIn === null && <Loader type={LoaderType.fullScreen} />}
          {isLoggedIn !== null && (
            <Routes>
              <Route
                path="/oauth/:social/redirect"
                element={<HandleSocials setError={setError} />}
              />
              {/* <Route
                  path="/dashboard"
                  element={
                    userData !== null ? (
                      <DashBoard
                        error={error}
                      />
                    ) : (
                      <Navigate to={"/"} />
                    )
                  }
                /> */}
              <Route
                path="/notifications"
                element={
                  userData !== null ? (
                    <NotificationsPage error={error} />
                  ) : (
                    <Navigate to={"/"} />
                  )
                }
              />
              <Route
                path="/settings"
                element={
                  userData !== null ? (
                    <SettingsPage error={error} setError={setError} />
                  ) : (
                    <Navigate to={"/"} />
                  )
                }
              />
              <Route
                path="/settings/user"
                element={
                  userData !== null ? (
                    <UserSettingPage />
                  ) : (
                    <Navigate to={"/"} />
                  )
                }
              />
              <Route
                path="/"
                element={
                  userData !== null ? (
                    <Navigate to={"/notifications"} />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route
                path="/signup"
                element={
                  userData !== null ? (
                    <Navigate to={"/notifications"} />
                  ) : (
                    <SignInPage />
                  )
                }
              />
              <Route
                path="/history"
                element={
                  userData !== null ? (
                    <HistoryPage />
                  ) : (
                    <LoginPage />
                  )
                }
              />
              <Route path="*" element={<NotFoundErrorPage />} />
            </Routes>
          )}
        </div>
      </section>
    </Suspense>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <WalletProvider
      defaultNetwork={mainnet}
      walletConnectChainIds={walletConnectChainIds}
    >
      <UserProvider>
        <BlockchainDataProvider>
          <App />
        </BlockchainDataProvider>
      </UserProvider>
    </WalletProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
