import React, { useEffect, useState } from "react";
import { UserNotification } from "models/Interfaces";
import { BlockchainType } from "models/Enums";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { getBlockchainForAssets } from "models/utils/blockchainTranslations";
import { CopyButton } from "components/CopyButton";
import { useTranslation } from "react-i18next";

const GovernancePollCard: React.FC<{
  copyToClipboard: Function;
  notification: UserNotification;
  getTypeDesc: Function;
  copy: boolean;
}> = ({ copyToClipboard, notification, getTypeDesc, copy }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);
  const { projects } = useContextBlockchainData();

  const [projectName, setProjectName] = useState<null | string>(null);
  const [projectLogo, setProjectLogo] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);

  let blockchain: string | null;
  let blockchainIcon: string;

  //TODO we need to make this piece of code dynamic and blockchain agnostic
  switch (notification.chainId) {
    case BlockchainType.Terrav2:
      blockchainIcon =
        "https://assets.hermesprotocol.io/img/projects/Terra.png";
      break;
    case BlockchainType.TerraClassic:
      blockchainIcon =
        "https://assets.hermesprotocol.io/img/projects/TerraClassic.png";
      break;
  }

  const getBlockchainData = async () => {
    try {
      const url = `${process.env.REACT_APP_ASSETS_REPO}${process.env.REACT_APP_ASSETS_CHAINS}${process.env.REACT_APP_ASSETS_CHAINS_LIST}`;
      const connection = await fetch(url, {
        method: "GET",
      });
      const blockchainData = await connection.json();
      if (blockchain) {
        setProjectName(blockchainData[blockchain].name);
        setProjectLogo(blockchainData[blockchain].icon);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const getProjectData = async () => {
    try {
      const url = `${process.env.REACT_APP_ASSETS_REPO}${process.env.REACT_APP_ASSETS_CHAINS}${blockchain}/${process.env.REACT_APP_ASSETS_GOVERNANCE}`;
      const connection = await fetch(url, {
        method: "GET",
      });
      const projectData = await connection.json();
      setProjectName(projectData[notification.walletAddress].name);
      setProjectLogo(projectData[notification.walletAddress].icon);
    } catch (error) {
      //! this must be removed once Assets have terra classic on governance
      const project = projects?.find((project) => {
        return project.project_market_id === notification.projectId;
      });
      if (project) {
        setProjectName(project?.project_desc);
        setProjectLogo(project?.image_url);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    blockchain = getBlockchainForAssets(notification.chainId);
    if (notification.walletAddress === "terra") {
      getBlockchainData();
    } else {
      getProjectData();
    }
  }, [notification, projects]);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="mb-2 h-1/6">
        {getTypeDesc(notification.notificationTypeId.slice(-4))}
      </h4>
      <div className="flex items-center transition h-4/6">
        <div className="flex items-center w-3/4">
          <div className="text-sm">
            {isLoading && <div className="loading-ring gray-dark"></div>}
            {!isLoading && projectName}
          </div>
          <CopyButton
            hoverText={t("notifications.copy_address", "common")}
            clickText={t("notifications.copied", "common")}
            isCopying={copy}
            onCopy={copyToClipboard}
            copiedElement={notification?.walletAddress}
          />
        </div>
        {isLoading && <div className="loading-ring gray-dark"></div>}
        {!isLoading && (
          <img
            src={projectLogo ? projectLogo : blockchainIcon}
            alt=""
            width={70}
            className="w-2/8 rounded"
          />
        )}
      </div>
    </div>
  );
};
export default GovernancePollCard;
