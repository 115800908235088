import { useEditNotificationFlow } from "contexts/edit-notification-flow";
import { useTranslation } from "react-i18next";
/* import EditNotificationConfigurationStep from "./EditNotificationConfigurationStep"; */
import PopUp from "./Popup";

function NotificationEditFlow() {
  const editNotificationFlow = useEditNotificationFlow();
  const { t } = useTranslation(["common", "enumerations"]);

  if (!editNotificationFlow.isActive) {
    return null;
  }

  /**
   * ! not used
   */
  return (
    <PopUp>
      {/* {editNotificationFlow.startRemoveNotification === false && (
        <EditNotificationConfigurationStep
          handleCancel={editNotificationFlow.cancelEditNotification}
          handleRemoveNotification={
            editNotificationFlow.startRemovingNotification
          }
          handleGoToNext={editNotificationFlow.saveEditNotification}
          currentPlatform={editNotificationFlow.selectedPlatformType!}
          currentType={editNotificationFlow.selectedNotificationType!}
          currentConfiguration={
            editNotificationFlow.selectedNotificationConfiguration!
          }
          currentSocialConfigs={
            editNotificationFlow.selectedNotificationSocial!
          }
          isEditingNotification={editNotificationFlow.editingNotification}
        />
      )}
      {editNotificationFlow.startRemoveNotification === true && (
        <ConfirmationPopup
          title={t(`remove_notification`, { ns: "common" })}
          message={t(`remove_message`, { ns: "common" })}
        >
          <div className="flex justify-end pt-4">
            <div className="flex">
              <Button
                onClick={editNotificationFlow.cancelEditNotification}
                type={ButtonType.primary}
                size={ButtonSize.smallWide}
                justify={ButtonJustify.center}
              >
                {t("action_cancel", { ns: "common" })}
              </Button>
              {editNotificationFlow.removingNotification && (
                <>
                  <Button
                    onClick={editNotificationFlow.removeNotification}
                    className="ml-2"
                    type={ButtonType.cancel}
                    size={ButtonSize.smallWide}
                    justify={ButtonJustify.center}
                    loading={true}
                    loadingTxt={t("action_remove", { ns: "common" })}
                  >
                    {t("action_remove", { ns: "common" })}
                  </Button>
                </>
              )}
              {!editNotificationFlow.removingNotification && (
                <>
                  <Button
                    onClick={editNotificationFlow.removeNotification}
                    className="ml-2"
                    type={ButtonType.cancel}
                    size={ButtonSize.smallWide}
                    justify={ButtonJustify.center}
                  >
                    {t("action_remove", { ns: "common" })}
                  </Button>
                </>
              )}
            </div>
          </div>
        </ConfirmationPopup>
              )} */}
    </PopUp>
  );
}

export default NotificationEditFlow;
