import React, { useEffect, useState } from "react";
import { UserNotification } from "models/Interfaces";
import { useTranslation } from "react-i18next";
import { CopyButton } from "components/CopyButton";

const DomainChangesCard: React.FC<{
  copyToClipboard: Function;
  notification: UserNotification;
  getTypeDesc: Function;
  copy: boolean;
}> = ({ copyToClipboard, notification, getTypeDesc, copy }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //   setIsLoading(true);
  }, [notification]);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="h-1/6">
        {getTypeDesc(notification.notificationTypeId.slice(-4))}
      </h4>

      <div className="flex-col items-center transition h-4/6">
        <div className="flex items-center h-1/6 mb-4">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && (
            <>
              <h6 className="font-medium text-black-transparent50">
                {notification.domain.name}
                {"." + notification.domain.tld}
                {notification?.regex && <small> (Regex) </small>}
              </h6>
            </>
          )}
          <CopyButton
            hoverText={t("notifications.copy_address", "common")}
            clickText={t("notifications.copied", "common")}
            isCopying={copy}
            onCopy={copyToClipboard}
            copiedElement={
              notification.domain.name + "." + notification.domain.tld
            }
          />
        </div>
        <div className="w-full flex items-center w-3/4">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && (
            <p className="font-medium text-sm text-black-transparent50">
              {notification.metadataChanges &&
                t("domain_changes_options.metadataChanges", {
                  ns: "enumerations",
                })}
              {notification.metadataChanges && notification.transfer && " / "}
              {notification.transfer &&
                t("domain_changes_options.transfer", {
                  ns: "enumerations",
                })}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default DomainChangesCard;
