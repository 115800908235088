import BlockchainImage from "components/BlockchainImage";
import {
  NewNotificationFlowStep,
  useNewNotificationFlow,
} from "contexts/new-notification-flow";
import { NotificationType } from "models/Enums";
import { useTranslation } from "react-i18next";

import PopUp, { PopupSize } from "../../Popup";
import BlockchainStep from "../notificationSteps/BlockchainStep";
import ConfigurationStep from "../notificationSteps/ConfigurationStep";
import NotificationAlertTypeStep from "../notificationSteps/NotificationTypeStep";

/* Start of the new notification flow - adding of a new notification */
function NewNotificationFlow() {
  const { t } = useTranslation(["common", "enumerations"]);
  const newNotificationFlow = useNewNotificationFlow();

  if (!newNotificationFlow.isActive) {
    return null;
  }

  /*
  TODO: this function need to be upgraded including a button to increase and decrease popup window size
  Slice function is needed for database names purposes
  */
  const resizeWindow = () => {
    const notifType = newNotificationFlow.selectedNotificationType?.slice(-4);

    return NewNotificationFlowStep.notificationConfigStep ===
      newNotificationFlow.currentStep &&
      notifType === NotificationType.NftMonitor
      ? true
      : false;
  };

  return (
    /*     <PopUp size={resizeWindow() ? PopupSize.largeXL : PopupSize.large}> */
    <PopUp size={PopupSize.largeXL}>
      {/* Notification Step title */}
      {newNotificationFlow.currentStep !==
        NewNotificationFlowStep.notificationConfigStep && (
        <div className="flex justify-between items-center pb-4 border-b border-black-line w-full ">
          <p className="text-xl font-bold">
            {t("notifications.new_notification_title", { ns: "common" })}
          </p>
          {newNotificationFlow.selectedNotificationBlockchain &&
            newNotificationFlow.currentStep !==
              NewNotificationFlowStep.blockchainStep && (
              <BlockchainImage
                blockchainId={
                  newNotificationFlow.selectedNotificationBlockchain
                }
              />
            )}
        </div>
      )}
      {/*  Notification Step components */}
      {newNotificationFlow.currentStep ===
        NewNotificationFlowStep.blockchainStep && (
        <BlockchainStep
          handleCancel={newNotificationFlow.cancelCreateNewNotification}
          handleGoToNext={newNotificationFlow.goToNotificationAlertTypes}
        />
      )}
      {newNotificationFlow.currentStep ===
        NewNotificationFlowStep.alertTypeStep && (
        <NotificationAlertTypeStep
          handleCancel={newNotificationFlow.cancelCreateNewNotification}
          handleGoToNext={newNotificationFlow.goToNotificationConfigs}
          handleGoBack={newNotificationFlow.goBack}
          blockchain={newNotificationFlow.selectedNotificationBlockchain!}
        />
      )}

      {/* handleGoToNext if not has socials, this will the last step */}

      {newNotificationFlow.currentStep ===
        NewNotificationFlowStep.notificationConfigStep && (
        <ConfigurationStep
          handleCancel={newNotificationFlow.cancelCreateNewNotification}
          handleGoToNext={newNotificationFlow.submitNewNotification}
          handleGoBack={newNotificationFlow.goBack}
          currentPlatform={newNotificationFlow.selectedPlatformType!}
          currentType={newNotificationFlow.selectedNotificationType!}
          isCreatingNotification={newNotificationFlow.creatingNotification}
        />
      )}
    </PopUp>
  );
}

export default NewNotificationFlow;
