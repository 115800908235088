export const getBlockchainForAssets = (chain: string) => {
  switch (chain) {
    case "terra":
    case "Terrav2":
    case "zb1_002":
      return "terra";
    case "terraClassic":
    case "TerraClassic":
    case "zb1_001":
      return "terraClassic";
    default:
      return null;
  }
};
