import { IconSettings } from "../settingsCards/IconSettings";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContextUser } from "contexts/user";
import { instanceOfUserData } from "utils/instanceOf";
import classNames from "classnames";
import Loader, { LoaderType } from "components/Loader";

export const UserSettingsEdit: React.FC<{}> = () => {
  const { t } = useTranslation(["common"]);
  const { userData, setUser } = useContextUser();
  const [newDataUser, setNewDataUser] = useState<string>(
    userData?.clientAlias ?? ""
  );
  const [showSave, setShowSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const changeAlias = async () => {
    setNewDataUser(newDataUser.trim()); // remove spaces to presentation on input
    try {
      setIsLoading(true);
      if (userData) {
        const connection = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/fauna/user/${userData.userId}`,
          {
            method: "PATCH",
            body: JSON.stringify({ alias: newDataUser.trim() }),
            credentials: "include",
          }
        );

        const dataFromApi = await connection.json();
        if (
          typeof dataFromApi.data === "object" &&
          instanceOfUserData(dataFromApi.data)
        ) {
          setUser(dataFromApi.data);
        }
      }
      setShowSave(false);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleChange = (e: any) => {
    setNewDataUser(e.target.value);
    setShowSave(true);
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div className="relative userSettings md:w-full flex items-center md:px-6 pb-8 py-5 max-w-6xl">
        <section className="px-2 md:px-0 flex flex-col w-full">
          <div>
            <h5>{t("settings.inputLabel", { ns: "common" })}</h5>
            <div className="relative cursor-pointer mt-3">
              <button
                className={classNames("absolute right-0 p-2", {
                  hidden: !showSave,
                })}
                onClick={changeAlias}
              >
                <IconSettings
                  icon="save"
                  bgContainerTransparent={true}
                  iconStyle="fas"
                  textColor="text-gold"
                  fontSize="text-xl"
                  className="float-right"
                />
              </button>
              <input
                id="aliasField"
                type="text"
                className="w-full block bg-gray-extralight15 h-8 border-b-2 opacity-1 h-5 border-transparent focus:border-transparent appearance-none outline-none focus:outline-none focus:ring-transparent"
                defaultValue={userData?.clientAlias ?? ""}
                onChange={(e) => handleChange(e)}
                maxLength={10}
                value={newDataUser}
              />
              <hr className="text-gray-lightmedium" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
