export enum PlatformType {
  Anchor = "zb2_0001",
  TerraSwap = "zb2_0003",
  Astroport = "zb2_0004",
  Mirror = "zb2_0006",
  Loop = "zb2_0005",
  Validators = "zb2_0011",
  General = "zb2_0012",
  TFM = "zb2_0019",
  PhoenixFinance = "zb2_0020",
  Luart = "zb2_0008",
  RandomEarth = "zb2_0024",
  KnowhereArt = "zb2_0025",
  OnePlanet = "zb2_0026",
  Talis = "zb2_0027",
  MessierArt = "zb2_0028",
  AllDerivatives = "zb2_9996",
  AllDEX = "zb2_9997",
  AllNft = "zb2_9992",
  SayveProtocolYieldOptimizer = "zb2_0032",
  BackboneLabs = "zb2_0034",
  LNS = "zb2_0035",
}

export enum BlockchainType {
  TerraClassic = "zb1_001",
  Terrav2 = "zb1_002",
}

export enum CategoryType {
  Dex = "zb3_0001",
  Derivatives = "zb3_0002",
  NFT = "zb3_0003",
  Blockchain = "zb3_0004",
  Launchpad = "zb3_0005",
  Lending = "zb3_0006",
  YieldStrategies = "zb3_0007",
  Staking = "zb3_0008",
  Payments = "zb3_0009",
  Infrastructure = "zb3_0010",
  DomainSystem = "zb3_0011",
}

export enum NotificationType {
  PriceAlert = "0021",
  GovernancePoll = "0007",
  WalletWatcher = "0024",
  NftMonitor = "0020",
  ProfileChanges = "0003",
  NewSale = "0027",
  LiquidityPool = "0028",
  LiquidStaking = "0029",
  DomainChanges = "0030" /*  */,
  DomainRegistration = "0031",
  NewDomain = "0032",
  Staking = "0033",
}

export enum ScopeNotification {
  myWallet = "zc5_0001",
  unique = "zc5_0002",
  otherWallet = "zc5_0003",
  myDomain = "zc5_0004",
  otherDomain = "zc5_0005",
}

export enum LiquidStaking {
  stakes = "stakes",
  queueUnbond = "queueUnbond",
  submitBatch = "submitBatch",
  finishUnbond = "finishUnbond",
}

export enum LiquidStakingPrice {
  stakes = "stakes",
  queueUnbond = "queueUnbond",
  submitBatch = "submitBatch",
  finishUnbond = "finishUnbond",
}

export enum Staking {
  delegate = "delegate",
  undelegate = "undelegate",
  redelegate = "redelegate",
  withdrawRewards = "withdrawRewards",
}

export enum ScopeNotificationWallets {
  myWallet = "zc5_0001",
  unique = "zc5_0003",
}

export enum DomainOptions {
  uponRenewal = "renewed",
  uponExpirationWeek = "weekbefore",
  uponExpiration24 = "h24before",
  uponExpirationMonth = "monthbefore",
  uponExpiration48 = "h48before",
  uponExpiration = "expired",
}
export enum SocialChannel {
  //Twitter = 'TWITTER',
  Discord = "DISCORD",
  /*     Telegram = 'TELEGRAM', */
}

export enum Social {
  discord = "discord",
  twitter = "twitter",
  /*     telegram = 'telegram', */
}

export enum ContractPairs {
  UlunaUusd = "ULUNA_UUSD",
  UlunaUkrw = "ULUNA_UKRW",
  BLunaUluna = "BLUNA_ULUNA",
}

export enum NftMonitor {
  buySell = "zc6_0001",
  list = "zc6_0002",
  cancelList = "zc6_0003",
  mint = "zc6_0004",
  bid = "zc6_0005",
  cancelBid = "zc6_0006",
  changeBid = "zc6_0007",
  auction = "zc6_0008",
  cancelAuction = "zc6_0009",
}

export enum Protocol {
  MainNet = "mainNet",
  Classic = "Classic",
}

export enum LiquidityPoolAlertConfigs {
  deposits = "deposits",
  withdrawals = "withdrawals",
}

export enum LiquidityPoolPlaceValue {
  absolute = "absolute",
  relative = "relative",
}

export enum NotAllowed {
  list = "list",
  cancelList = "cancelList",
  bid = "bid",
  cancelBid = "cancelBid",
  changeBid = "changeBid",
  auction = "auction",
  cancelAuction = "cancelAuction",
}

export enum DomainChangesOptions {
  metadataChanges = "metadataChanges",
  transfer = "transfer",
}

export enum AvailableTlds {
  luna = "luna",
}
