import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Warning } from "components/modals";
import PopUp, { PopupSize } from "components/Popup";
import { useContextUser } from "contexts/user";
import { NotificationType, SocialChannel } from "models/Enums";
import { UserNotification } from "models/Interfaces";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContextBlockchainData } from "../../../contexts/blockchain-data";
import { ConfigurationParams } from "../../../models/Notification";
import Button, { ButtonSize, ButtonType } from "../../Button";
import SocialChannels from "../notificationSteps/SocialChannels";
import {
  GovernancePoll,
  LiquidityPool,
  LiquidStaking,
  NFTMonitor,
  ProfileChanges,
  WalletWatcher,
} from "../notificationTypes";
import PriceAlert from "../notificationTypes/PriceAlert";
import NewSale from "../notificationTypes/NewSale";
import DomainChanges from "../notificationTypes/DomainChanges";
import DomainRegistration from "../notificationTypes/DomainRegistration";
import NewDomain from "../notificationTypes/NewDomain";
import Staking from "../notificationTypes/Staking";

export const EditNotification = ({
  notification,
  closeModal,
  setNotification,
  saveNotification,
  status,
  setStatus,
  socialChannels,
  setSocialChannels,
  selectedValues,
  setSelectedValues,
}: {
  notification: UserNotification | undefined;
  closeModal: Dispatch<SetStateAction<any>>;
  setNotification: Dispatch<SetStateAction<any>>;
  saveNotification(data?: boolean): void;
  status: boolean;
  setStatus: Dispatch<SetStateAction<any>>;
  socialChannels: SocialChannel[];
  setSocialChannels: Dispatch<SetStateAction<any>>;
  selectedValues: ConfigurationParams;
  setSelectedValues: Dispatch<SetStateAction<any>>;
}) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { types } = useContextBlockchainData();
  const [modalDelete, setModalDelete] = useState<boolean | null>(null);
  const { userData } = useContextUser();
  const [releaseButton, setReleaseButton] = useState<boolean>(false);

  const getTypeDesc = () => {
    return types?.filter(
      (t) => t.notification_type_id === notification?.notificationTypeId
    )[0].notification_type_desc;
  };

  const showWarningDelete = () => {
    setModalDelete(true);
  };

  const handleDeleteNotification = () => {
    closeModal(true);
    saveNotification(true);
  };

  const handleClickChangeStatus = () => {
    if (hasSocialAccount() && socialChannels.length > 0) {
      setStatus(!status);
      setNotification({
        ...notification,
        status: status ? "1" : "0",
      });
      showStatus();
    }
  };

  const hasSocialAccount = (channel?: string): boolean => {
    if (!userData?.socials?.length) {
      return false;
    }

    if (channel) {
      return userData?.socials.filter(
        (c) => c.type.toUpperCase() === channel && c.status !== 0
      ).length !== 0
        ? true
        : false;
    }
    return true;
  };

  const showStatus = () => {
    const element = document.querySelector(".status");
    element?.classList.remove("opacity-0");
    setTimeout(() => element?.classList.add("opacity-0"), 1500);
  };

  useEffect(() => {
    if (notification) {
      notification.discordId.length > 0 &&
        setSocialChannels([...socialChannels, SocialChannel.Discord]);

      /*  notification.telegramId.length > 0 &&
        setSocialChannels([...socialChannels, SocialChannel.Telegram]); */
    }
  }, []);

  useEffect(() => {
    if (notification && socialChannels.length > 0) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [socialChannels]);

  return (
    <div className="p-3 rounded-sm relative w-full h-full">
      <div className={`flex`}>
        <h2 className="flex-grow">{getTypeDesc()} Alert</h2>
        <div className={`block ${!hasSocialAccount() && "opacity-50"}`}>
          <label className="switch block">
            <input
              type="checkbox"
              onChange={handleClickChangeStatus}
              disabled={!hasSocialAccount()}
              checked={status}
            />
            <span
              className={` ${
                !hasSocialAccount() && "opacity-70"
              } slider round checked:bg-black`}
            ></span>
          </label>
          <div className="block">
            <small className="status ml-1 opacity-0 ease delay-75 transition-opacity in-out">
              {status ? "Active" : "Inactive"}
            </small>
          </div>
        </div>
      </div>
      <div className="pb-3 w-full">
        {types
          ?.filter(
            ({ project_market_id, notification_type_id }) =>
              project_market_id === notification?.projectId &&
              notification_type_id === notification.notificationTypeId
          )
          .map((el) => {
            let notificationTypeId = el.notification_type_id.slice(-4);
            /* Governance Pool Alert */
            return (
              (NotificationType.GovernancePoll === notificationTypeId && (
                <div>
                  <GovernancePoll notification={notification} />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) ||
              /* Wallet Watcher  */
              (NotificationType.WalletWatcher === notificationTypeId && (
                <div>
                  <WalletWatcher
                    disabledBtn={setReleaseButton}
                    setWallet={setSelectedValues}
                    notification={notification}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) /* Profile Changes Alert */ ||
              (NotificationType.ProfileChanges === notificationTypeId && (
                <div>
                  <ProfileChanges
                    setValues={setSelectedValues}
                    notification={notification}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) ||
              /*  NFT Monitor Alert */
              (NotificationType.NftMonitor === notificationTypeId && (
                <div>
                  <NFTMonitor
                    setValues={setSelectedValues}
                    selectedValues={selectedValues}
                    notification={notification}
                    editView={true}
                    disabledBtn={setReleaseButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) ||
              (NotificationType.PriceAlert === notificationTypeId && (
                <div>
                  <PriceAlert
                    setValues={setSelectedValues}
                    notification={notification}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) ||
              (NotificationType.NewSale === notificationTypeId && (
                <div>
                  <NewSale notification={notification} />
                </div>
              )) ||
              (NotificationType.LiquidityPool === notificationTypeId && (
                <div>
                  <LiquidityPool
                    disabledBtn={setReleaseButton}
                    setValues={setSelectedValues}
                    notification={notification}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) ||
              (NotificationType.DomainRegistration === notificationTypeId && (
                <div>
                  <DomainRegistration
                    setValues={setSelectedValues}
                    notification={notification}
                  />
                </div>
              )) ||
              (NotificationType.NewDomain === notificationTypeId && (
                <div>
                  <NewDomain notification={notification} />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) ||
              (NotificationType.LiquidStaking === notificationTypeId && (
                <div>
                  <LiquidStaking
                    notification={notification}
                    disabledBtn={setReleaseButton}
                    setValues={setSelectedValues}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) ||
              (NotificationType.Staking === notificationTypeId && (
                <div>
                  <Staking
                    notification={notification}
                    disabledBtn={setReleaseButton}
                    setValues={setSelectedValues}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              )) ||
              (NotificationType.DomainChanges === notificationTypeId && (
                <div>
                  <DomainChanges
                    setValues={setSelectedValues}
                    notification={notification}
                    disabledBtn={setReleaseButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                    notification={notification}
                  />
                </div>
              ))
            );
          })}
      </div>
      <div className="px-3">
        <div className="flex items-center">
          <div className="flex flex-grow">
            <Button
              alt={"Delete notification"}
              className={` ${
                modalDelete === false
                  ? "bg-red-400 text-white"
                  : "text-gray-light40 hover:text-gray-lightmedium"
              } mx-2 px-2 py-2 `}
              type={ButtonType.cancel}
              size={ButtonSize.small}
              onClick={showWarningDelete}
              disabled={modalDelete === false}
              noTransition={true}
            >
              <FontAwesomeIcon icon={"trash"} />
            </Button>
          </div>
          <div className="flex">
            <Button
              type={ButtonType.cancel}
              size={ButtonSize.square}
              className={"mx-2"}
              onClick={closeModal}
            >
              {t("action_cancel", { ns: "common" })}
            </Button>

            <Button
              disabled={releaseButton}
              type={ButtonType.primary}
              size={ButtonSize.square}
              onClick={() => saveNotification()}
            >
              {t("action_save", { ns: "common" })}
            </Button>
          </div>
        </div>
      </div>
      {modalDelete && (
        <PopUp size={PopupSize.small} backDrop={false}>
          <Warning
            iconWarning={true}
            title={"Attention"}
            text={t("remove_message", { ns: "common" })}
          />
          <div className="flex justify-end px-4 mt-5">
            <p
              className="mx-2 cursor-pointer text-base underline"
              onClick={() => setModalDelete(null)}
            >
              {t("action_cancel", {
                ns: "common",
              })}
            </p>
            <p
              className="mx-2 text-base cursor-pointer underline text-gold"
              onClick={handleDeleteNotification}
            >
              {t("action_delete", { ns: "common" })}
            </p>
          </div>
        </PopUp>
      )}
    </div>
  );
};
