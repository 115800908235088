import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from "components/Button";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { useContextUser } from "contexts/user";
import { BlockchainType } from "models/Enums";
import { Blockchain } from "models/Interfaces";

const BlockchainStep: React.FC<{
  handleCancel(): void;
  handleGoToNext(blockchainType: BlockchainType): void;
}> = ({ handleCancel, handleGoToNext }) => {
  const { blockchains } = useContextBlockchainData();
  const { t } = useTranslation(["common"]);
  const { userData } = useContextUser();
  const newNotificationFlow = useNewNotificationFlow();
  const [blockchainChoosen, setBlockchainChoosen] = useState<string>(
    newNotificationFlow.selectedNotificationBlockchain || ""
  );

  /**
   * Set newNotificationFlow props.
   * At the moment, it verify if the user has defined social Channels and sets the flow prop.
   * TODO: pass this code to flow
   */
  const setStatusInFlow = () => {
    newNotificationFlow.setStatusNotification(!userData?.socials ? "0" : "1");
    return !userData?.socials ? 0 : 1;
  };

  /**
   * Hard valitation is the selected blockchain is able to create a notification.
   * The validation is from the Blockchains context
   */
  const isValidBlockchain = (chainId: string): string | boolean => {
    const objectChain = blockchains?.filter((c: Blockchain) => {
      return c?.chain_id === chainId;
    });
    if (!objectChain?.length) return false;
    return objectChain[0]?.chain_id;
  };

  const showBlockchainOptions = () => {
    return blockchains?.map((blockchain) => {
      return (
        <option value={blockchain.chain_id} key={blockchain.chain_id}>
          {t(`blockchains_types.${blockchain.chain_id}`, {
            ns: "enumerations",
          })}
        </option>
      );
    });
  };

  const handleGoToNextClick = () => {
    return blockchainChoosen && !isValidBlockchain(blockchainChoosen)
      ? false
      : handleGoToNext(blockchainChoosen as BlockchainType);
  };

  // set status notification
  useEffect(() => {
    setStatusInFlow();
  }, []);

  return (
    <>
      <div className="w-full">
        <div className="my-12 ">
          <select
            multiple={false}
            value={blockchainChoosen}
            className={`focus:ring-0 focus:outline-none`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setBlockchainChoosen(e?.target?.value)
            }
          >
            <option value="" selected disabled>
              {t("notifications.choose_blockchain", { ns: "common" })}
            </option>
            {showBlockchainOptions()}
          </select>
        </div>
        <div className="flex justify-end ">
          <Button
            onClick={handleCancel}
            type={ButtonType.cancel}
            size={ButtonSize.smallWide}
            justify={ButtonJustify.center}
          >
            {t("action_cancel", { ns: "common" })}
          </Button>
          <Button
            className="ml-2"
            disabled={!blockchainChoosen}
            type={ButtonType.primary}
            size={ButtonSize.smallWide}
            justify={ButtonJustify.center}
            onClick={handleGoToNextClick}
          >
            {t("action_next", { ns: "common" })}
          </Button>
        </div>
      </div>
    </>
  );
};

export default BlockchainStep;
