import Account from "components/Account";
import Logo from "components/Logo";
import SideMenu, { ModeType } from "components/SideMenu";
import SideMenuItem from "components/SideMenuItem";
import { Dispatch, SetStateAction, useState } from "react";
import { Tooltip } from "./Tooltip";
import { useTranslation } from "react-i18next";

export const Navbar: React.FC<{
  setIsCondensed: Dispatch<SetStateAction<any>>;
  isCondensed: boolean;
}> = ({ setIsCondensed, isCondensed }) => {
  const { t } = useTranslation(["common"]);

  return (
    <div className="fixed">
      <SideMenu mode={isCondensed ? ModeType.condensed : ModeType.normal}>
        <Logo
          onClick={() => setIsCondensed(!isCondensed)}
          condensed={isCondensed}
        />
        <Tooltip content={t('menu.coming_soon', { ns: "common" })}>
          <SideMenuItem
            icon="dashboard"
            title={t('menu.dashboard', { ns: "common" })}
            url="/dashboard"
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <SideMenuItem
          icon="notifications"
          title={t('menu.notifications', { ns: "common" })}
          url="/notifications"
          condensed={isCondensed}
          beta={true}
        />
          <SideMenuItem
            icon="history"
            title={t('menu.history', { ns: "common" })}
            url="/history"
            condensed={isCondensed}
            beta={true}
          />
        <Tooltip content={t('menu.coming_soon', { ns: "common" })}>
          <SideMenuItem
            icon="messages"
            title={t('menu.messages', { ns: "common" })}
            url="/messages"
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <Tooltip content={t('menu.coming_soon', { ns: "common" })}>
          <SideMenuItem
            icon="trade"
            title={t('menu.trade', { ns: "common" })}
            url="/trade"
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <SideMenuItem
          icon="settings"
          title={t('menu.settings', { ns: "common" })}
          url="/settings"
          condensed={isCondensed}
          beta={true}
        />
        {/* <UpgradeButton condensed={isCondensed} /> */}
        <div className="flex-grow justify-self-end flex items-end">
          <Account condensed={isCondensed} />
        </div>
      </SideMenu>
    </div>
  );
};
