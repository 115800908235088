import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Validator } from "@terra-money/terra.js";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { useContextUser } from "contexts/user";
import { BlockchainType, ScopeNotification } from "models/Enums";
import {
  getDelegationsByWalletAddress,
  getValidators,
  isValidWallet,
} from "../../../models/utils/terra";
import { UserNotification } from "models/Interfaces";
import { countUsersWallets } from "../utils";

const ProfileChanges = ({
  disabledBtn,
  setValues,
  notification,
}: {
  disabledBtn?: Dispatch<SetStateAction<any>>;
  setValues: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { userData } = useContextUser();
  const notificationFlow = useNewNotificationFlow();
  const { t } = useTranslation("common");
  const [scope, setScope] = useState<string | "">(
    notification ? notification.scope : ""
  );
  const [walletValidator, setWalletValidator] = useState<string | "">(
    notification ? notification.validatorAddress[0] : ""
  );
  const [validators, setValidators] = useState<Validator[] | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [matchValidators, setMatchValidators] = useState<Validator[]>([]);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [walletValidators, setWalletValidators] = useState<string[]>([]);

  /**
   * Set initial fields values.
   * Called when component is loading and on every validator select change to reset the other fields.
   */
  const resetFields = () => {
    disabledBtn && disabledBtn(true);
    setWalletValidator("");
    setScope("");
    setSearch(null);
    setMatchValidators([]);
  };

  /**
   * Handle Wallet Changed
   * @param e onChange event
   */
  const handleWalletChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setWalletAddress(e?.target.value);
    e?.target.value === ""
      ? disabledBtn && disabledBtn(true)
      : saveWallet(e.target.value, scope);
  };

  /**
   * Handle Scope Changed
   * @param e onChange event
   */
  const handleScopeChanged = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    resetFields();
    setScope(e.target?.value);
    if (
      e?.target?.value === ScopeNotification.myWallet &&
      userData &&
      countUsersWallets(userData) === 1
    ) {
      // set myWallet and user has more than one wallet
      saveWallet(userData?.wallets[0].walletAddress, e?.target?.value);
    }
  };

  /**
   * Save Wallet.
   * If my Wallet scope selected
   * @param address
   * @param scopeAux
   */
  const saveWallet = async (address: string, scopeAux: string) => {
    if (scopeAux === ScopeNotification.myWallet) {
      const validatorsByAddress = await getDelegationsByWalletAddress(
        address,
        notificationFlow.selectedNotificationBlockchain as BlockchainType
      );
      setWalletValidators(validatorsByAddress);
      setValues({
        scope: scopeAux,
        walletAddress: address,
        validatorAddress: validatorsByAddress,
      });
    } else setValues({ scope: scopeAux, validatorAddress: [address] });
    disabledBtn && disabledBtn(false);
  };
  /**
   * Handle Validator selected from selectbox list
   * @param e
   */
  const handleValidatorSelected = (
    e: React.MouseEvent<HTMLSpanElement>
  ): void => {
    const wallet = e.currentTarget.dataset.wallet;

    if (wallet) {
      setWalletValidator(wallet);
      setMatchValidators([]);
    }
  };

  /**
   * Fill validator address when validator selected from selectbox
   * @param e
   */
  const handleValidatorAddressChanged = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    showValidator(e?.target?.value);
    setWalletValidator(e?.target?.value);
  };

  /**
   * Handle step errors
   * @param address
   */
  const checkWalletValidator = (address: string) => {
    document.querySelector(".inputError")?.classList.add("hidden");

    if (isValidWallet(address)) {
      saveWallet(walletValidator, scope);
    } else {
      if (address !== "" && matchValidators?.length === 0) {
        document.querySelector(".inputError")?.classList.remove("hidden");
      }
      disabledBtn && disabledBtn(true);
    }
  };

  /**
   * Show Validator by name or address
   * @param word validator name or address
   */
  const showValidator = (word: string) => {
    const listValidators = validators?.filter((el) => {
      return (
        word &&
        (el?.description?.moniker
          ?.toLowerCase()
          .includes(word?.toLowerCase()) ||
          el?.operator_address?.toLowerCase().includes(word?.toLowerCase()))
      );
    });
    listValidators && setMatchValidators(listValidators);
  };

  // load validators list
  useEffect(() => {
    (async function () {
      if (!validators) {
        setValidators([]);
        await getValidators(
          notificationFlow.selectedNotificationBlockchain as BlockchainType
        ).then((data) => {
          data.length && setValidators(data);
        });
      }
    })();
  }, [handleScopeChanged]);

  useEffect(() => {
    checkWalletValidator(walletValidator);
  }, [walletValidator]);

  return (
    <>
      <div className="w-full">
        {!notification && (
          <div className="pb-4 border-b border-black-line w-full ">
            <h3>{t("notifications.profile_change", { ns: "common" })}</h3>
          </div>
        )}
        {/* Scope selectbox */}
        <div className="mt-6">
          <p className="font-medium">
            {t("notifications.scope", { ns: "common" })}
          </p>

          <select
            multiple={false}
            value={scope}
            onChange={handleScopeChanged}
            className="focus:ring-0 focus:outline-none "
          >
            <option value="">
              {t(`notifications.select_scope`, { ns: "common" })}
            </option>
            {Object.values(ScopeNotification)
              .filter(
                (value) =>
                  value === ScopeNotification.myWallet ||
                  value === ScopeNotification.unique
              )
              .map((el) => {
                return (
                  <option value={el} key={Math.random() * 0.5}>
                    {t(`scope_notifications.validator.${el}`, {
                      ns: "enumerations",
                    })}
                  </option>
                );
              })}
          </select>
        </div>

        {/* My Wallet selectbox - if myWallet scope selected */}
        <div className="mt-6">
          {(scope || notification) && scope === ScopeNotification.myWallet && (
            <>
              <p>
                {t("notifications.choose_wallet", {
                  ns: "common",
                })}
              </p>
              <select
                multiple={false}
                onChange={handleWalletChanged}
                value={walletAddress}
                className={`focus:border-0 myWallet focus:ring-0 focus:outline-none`}
                disabled={countUsersWallets(userData) === 1}
              >
                {countUsersWallets(userData) > 1 && (
                  <option value="">
                    {t(`notifications.select_wallet`, { ns: "common" })}
                  </option>
                )}

                {userData?.wallets?.map((w) => (
                  <option value={w.walletAddress}>
                    {w.walletAlias || w.walletAddress}
                  </option>
                ))}
              </select>
              {scope === ScopeNotification.myWallet && (
                <div className="mt-2">
                  <small>
                    <span className="text-red-500">{" * "}</span>
                    {t(`notifications.validators_disclaimer`, { ns: "common" })}
                  </small>
                </div>
              )}
            </>
          )}

          {/* Validator input & selectbox - if uniqueValidator scope selected */}
          {scope === ScopeNotification.unique && (
            <>
              <p>
                {t("alert_configuration_settings.validator_address", {
                  ns: "common",
                })}
              </p>
              <div className="relative autoCompleteInput ">
                <input
                  onChange={handleValidatorAddressChanged}
                  type="text"
                  value={walletValidator}
                  placeholder={`${
                    !validators?.length
                      ? "Loading..."
                      : "Name Validator / Address"
                  }`}
                  className={`focus:ring-0 focus:opacity-100 focus:border-gray-extralight90medium focus:outline-none text-sm rounded w-full py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-gray-medium ${
                    !validators?.length && "bg-gray-extralight20"
                  }`}
                  disabled={!validators?.length && true}
                />

                {!validators?.length && (
                  <button
                    type="button"
                    className=" btn-wrapper bg-indigo-500 block"
                    disabled
                  >
                    <div className=" loading-ring gray-dark"></div>
                  </button>
                )}
              </div>
              <small className="px-1 inputError hidden text-red-500">
                {t("notifications.invalid_validator", { ns: "common" })}
              </small>
              {!isValidWallet(walletValidator) && (
                <div
                  className={` ${
                    !matchValidators.length && "hidden"
                  } overflow-hidden transition min-h-10 max-h-36 mt-2 customScrollbar overflow-y-auto shadow-inner border border-gold20 shadow-xl`}
                >
                  <div className=" rounded hover:bg-fixed ">
                    <div className="w-full divide-y px-1 border-stone-50 px-3 ">
                      {matchValidators?.map((validator) => {
                        return (
                          <>
                            <div
                              className="pt-4 pb-1 px-1 cursor-pointer"
                              key={validator.operator_address}
                            >
                              <span
                                title={validator.description.moniker}
                                data-wallet={validator.operator_address}
                                className="hover:text-gold text-sm transition hover:underline"
                                onClick={handleValidatorSelected}
                              >
                                {validator.description.moniker}
                              </span>
                              <a
                                className="ml-2"
                                target="_blank"
                                rel="noreferrer"
                                href={`https://station.terra.money/validator/${validator.operator_address}`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                  width={15}
                                  height={15}
                                  className="inline"
                                >
                                  <path d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" />
                                </svg>
                              </a>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileChanges;
