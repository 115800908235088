import React, { useEffect, useState } from "react";
import { UserNotification } from "models/Interfaces";
import { useTranslation } from "react-i18next";
import { CopyButton } from "components/CopyButton";

const DomainRegistrationCard: React.FC<{
  copyToClipboard: Function;
  notification: UserNotification;
  getTypeDesc: Function;
  copy: boolean;
}> = ({ copyToClipboard, notification, getTypeDesc, copy }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //   setIsLoading(true);
  }, [notification]);

  return (
    <div className="flex flex-col justify-between z-10 h-5/8">
      <h4 className="h-1/6">
        {getTypeDesc(notification.notificationTypeId.slice(-4))}
      </h4>

      <div className="flex-col items-center transition h-4/6 ">
        <div className="flex items-center h-1/6 mb-4">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && (
            <>
              <h6 className="font-medium text-black-transparent50">
                {notification.domain.name}
              </h6>
              <CopyButton
                hoverText={t("notifications.copy_address", "common")}
                clickText={t("notifications.copied", "common")}
                isCopying={copy}
                onCopy={copyToClipboard}
                copiedElement={notification.domain.name}
              />
            </>
          )}
        </div>
        <div className="w-full flex items-center w-3/4">
          {isLoading && <div className="loading-ring gray-dark"></div>}
          {!isLoading && (
            <div className="flex flex-col">
              <p className="font-medium text-sm text-black-transparent50">
                {notification.options.h24before.flag &&
                  t("domain_options_simplified.h24before", {
                    ns: "enumerations",
                  })}
                {notification.options.h24before.flag &&
                  (notification.options.h48before.flag ||
                    notification.options.weekbefore.flag ||
                    notification.options.monthbefore.flag) &&
                  " / "}
                {notification.options.h48before.flag &&
                  t("domain_options_simplified.h48before", {
                    ns: "enumerations",
                  })}
                {notification.options.h48before.flag &&
                  (notification.options.weekbefore.flag ||
                    notification.options.monthbefore.flag) &&
                  " / "}
                {notification.options.weekbefore.flag &&
                  t("domain_options_simplified.weekbefore", {
                    ns: "enumerations",
                  })}
                {notification.options.weekbefore.flag &&
                  notification.options.monthbefore.flag &&
                  " / "}
                {notification.options.monthbefore.flag &&
                  t("domain_options_simplified.monthbefore", {
                    ns: "enumerations",
                  })}
                {(notification.options.h24before.flag ||
                  notification.options.h48before.flag ||
                  notification.options.weekbefore.flag ||
                  notification.options.monthbefore.flag) &&
                  ` ${t("domain_options_simplified.before_expiration", {
                    ns: "enumerations",
                  })}`}
              </p>
              <p className="font-medium text-sm text-black-transparent50">
                {notification.options.renewed.flag &&
                  t("domain_options_simplified.renewed", {
                    ns: "enumerations",
                  })}
                {notification.options.renewed.flag &&
                  notification.options.expired.flag &&
                  " / "}
                {notification.options.expired.flag &&
                  t("domain_options_simplified.expired", {
                    ns: "enumerations",
                  })}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DomainRegistrationCard;
