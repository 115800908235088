import React, { useEffect, useState } from "react";
import Button, { ButtonType, ButtonSize } from "components/Button";
import classNames from "classnames";
import { WalletStatus } from "@terra-money/wallet-provider";
import { useTranslation } from "react-i18next";
import { Tooltip } from "components/Tooltip";
import { BalloonType, Balloon } from "components/Balloon";
import { SignUpStep } from "views/SignIn";

export enum TooltipContent {
  connect = "connect-info",
  verify = "verify-info",
}

const SignupSectionButtons: React.FC<{
  onConnectWalletClick: React.MouseEventHandler;
  onVerifyWalletClick: React.MouseEventHandler;
  walletStatus: string;
  currentStep?: SignUpStep;
}> = ({ onConnectWalletClick, onVerifyWalletClick, walletStatus, currentStep }) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <div className="flex flex-row flex-nowrap	justify-center md:mt-8">
      <div className="flex flex-col">
        <div className="h-16 my-6 flex items-center justify-center">
          <Balloon
            type={BalloonType.index}
            disabled={walletStatus !== WalletStatus.WALLET_CONNECTED}
          >
            <span className="font-semibold text-lg">1</span>
          </Balloon>
        </div>
        <div className="h-16 my-6 mt-10 flex items-center justify-center">
          <Balloon
            type={BalloonType.index}
            disabled={currentStep !== SignUpStep.signUpCompleted}
          >
            <span className="font-semibold text-lg">2</span>
          </Balloon>
        </div>
      </div>
      <div className="flex flex-col relative">
        <div className="absolute h-16 border-l-2 border-dashed border-gray-extralight80 w-10 left-1/2 top-22"></div>
        <div
          className={classNames("h-16 my-6 flex items-center justify-center")}
        >
          <Button
            onClick={onConnectWalletClick}
            type={ButtonType.secondary}
            disabled={walletStatus === WalletStatus.WALLET_CONNECTED}
            size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
            fullWidth
          >
            <img
              src="./images/terra_icon.svg"
              className={classNames("inline")}
              alt="Terra Wallet"
            />
            <span className="pl-3 md:pl-6">
              {t(`wallet_action.connect`, { ns: "common" })}
            </span>
          </Button>
        </div>
        <div className="h-16 my-6 mt-10 flex items-center justify-center">
          <Button
            onClick={onVerifyWalletClick}
            type={ButtonType.secondary}
            disabled={walletStatus !== WalletStatus.WALLET_CONNECTED}
            size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
            fullWidth
          >
            <img
              src="./images/terra_icon.svg"
              className={classNames("inline")}
              alt="Terra Wallet"
            />
            <span className="pl-3 md:pl-6">
              {t(`wallet_action.verify`, { ns: "common" })}
            </span>
          </Button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="h-16 my-6 flex items-center justify-center">
          <Tooltip
            content={
              <span>
                Connect the wallet you want to link to your Hermes Account!
              </span>
            }
          >
            <Balloon type={BalloonType.info} id="connect-info">
              <span>i</span>
            </Balloon>
          </Tooltip>
        </div>
        <div className="h-16 my-6 mt-10 flex items-center justify-center">
          <Tooltip
            content={
              <span>
                Sign a transaction that will prove ownership of your wallet.{" "}
                <span className="underline">
                  It will not cost you anything.
                </span>
              </span>
            }
          >
            <Balloon type={BalloonType.info} id="connect-info">
              <span>i</span>
            </Balloon>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
export default SignupSectionButtons;
