import { Balloon, BalloonType } from "components/Balloon";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useContextUser } from "contexts/user";
import {
  LiquidStaking as LiquidStakingOptions,
  ScopeNotification,
} from "models/Enums";
import { UserNotification } from "models/Interfaces";
import { ConfigurationParams } from "models/Notification";
import { isValidWallet } from "models/utils/terra";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { countUsersWallets } from "../utils";

const LiquidStaking = ({
  setValues,
  disabledBtn,
  notification,
}: {
  setValues: Dispatch<SetStateAction<any>>;
  disabledBtn?: Dispatch<SetStateAction<any>>;
  notification?: UserNotification; // Liquid staking edit
}) => {
  const startFlags = { flag: false, value: 99999999, type: "" };
  const { t } = useTranslation(["common", "enumerations"]);
  const { userData } = useContextUser();
  const { priceTypes } = useContextBlockchainData();
  const [scope, setScope] = useState<string>();
  const [walletAddressInput, setWalletAddressInput] = useState<string>(
    notification!?.walletAddress || ""
  );

  const [configs, setConfigs] = useState<ConfigurationParams>();

  const resetFields = (): void => {
    setWalletAddressInput(""); // clear wallet address
    disabledBtn && disabledBtn(true); // disable button notification

    let loadData: ConfigurationParams = {
      contract:
        "terra1l2nd99yze5fszmhl5svyh5fky9wm4nz4etlgnztfu4e8809gd52q04n3ea",
      tokenContract:
        "terra17aj4ty4sz4yhgm08na8drc0v03v2jwr3waxcqrwhajj729zhl7zqnpc0ml",
      queueUnbond: startFlags,
      submitBatch: startFlags,
      finishUnbond: startFlags,
      stakes: startFlags,
    };
    if (notification) {
      // For edit notification
      loadData = {
        contract: notification?.contract,
        tokenContract: notification?.tokenContract,
        queueUnbond: { ...notification?.queueUnbond },
        submitBatch: { ...notification?.submitBatch },
        finishUnbond: { ...notification?.finishUnbond },
        stakes: { ...notification?.stakes },
        walletAddress: notification?.walletAddress,
      };
      setScope(notification.scope);
    }

    setConfigs({ ...loadData });
  };

  /**
   * Verify is the address given is a valid wallet address
   * @param text wallet address
   * @returns boolean true or false
   */
  const checkWallet = (text: string) => {
    if (!isValidWallet(text)) {
      text !== "" &&
        document.querySelector(".inputError")?.classList.remove("hidden");
      disabledBtn && disabledBtn(true);
      return false;
    } else {
      setWalletAddressInput(text);
      document.querySelector(".inputError")?.classList.add("hidden");

      return true;
    }
  };

  const hasOptionSelected = () => {
    return (
      configs?.finishUnbond?.flag ||
      configs?.stakes?.flag ||
      configs?.queueUnbond?.flag ||
      configs?.submitBatch?.flag
    );
  };

  const checkData = (configs: ConfigurationParams) => {
    if (
      walletAddressInput === "" ||
      (walletAddressInput !== "" && !checkWallet(walletAddressInput)) ||
      !hasOptionSelected()
    ) {
      // wallet address revalidation
      disabledBtn && disabledBtn(true);
    } else {
      if (hasOptionSelected()) {
        setValues({
          ...configs,
          scope: scope,
          walletAddress:
            (notification !== undefined && configs.walletAddress) ||
            walletAddressInput,
        });

        disabledBtn && disabledBtn(false);
      }
    }
  };
  /**
   * Handle scope selected by user
   *
   * @param e
   */
  const handleScope = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    resetFields();
    setScope(e.target.value);
    if (e?.target?.value === ScopeNotification.myWallet) {
      setWalletAddressInput(userData?.wallets[0]?.walletAddress || "");
    }
  };

  /**
   * Handle wallet by user
   * @param event onChange event
   */
  const handleWalletChanged = (event: React.ChangeEvent<any>) => {
    setWalletAddressInput(event?.target.value);
    document.querySelector(".inputError")?.classList.add("hidden"); // reset input field on writing
  };

  /**
   * Handle Alert Configurations checked
   * @param event onChange event
   */
  const handleLiquidStakingOptions = (event: React.ChangeEvent<any>) => {
    const { name, checked } = {
      name: event?.target.name,
      checked: event?.target.checked,
    };
    const currentConfigs = checked ? { value: 0, type: ">=" } : startFlags;
    setConfigs({
      ...configs,
      [name]: {
        flag: checked,
        ...currentConfigs,
      },
    });
  };

  /**
   * Handle Alert Type and price Changed
   * @param e onChange event
   */
  const handleTypeAndPriceStaking = (
    e: React.ChangeEvent<HTMLSelectElement | any>
  ) => {
    const { name, value } = { name: e?.target.name, value: e?.target.value };

    const currentConfigs =
      (name === LiquidStakingOptions.stakes && configs?.stakes) ||
      (name === LiquidStakingOptions.queueUnbond && configs?.queueUnbond) ||
      (name === LiquidStakingOptions.finishUnbond && configs?.finishUnbond) ||
      configs?.submitBatch;

    const result =
      e.target.localName === "input" ? { value: value } : { type: value };

    // set new configs
    setConfigs({
      ...configs,
      [name]: {
        ...currentConfigs,
        ...result,
      },
    });
  };

  /**
   * Search the value in object by key and return the result
   * @param object
   * @param key
   * @returns
   */
  const returnObjectByKey = (object: ConfigurationParams, key: string) => {
    return Object.entries(object).filter(
      ([prop, value]) => prop === key && value
    )[0];
  };

  useEffect(() => {
    resetFields();
  }, []);

  useEffect(() => {
    checkData({ ...configs });
  }, [configs]);

  useEffect(() => {
    checkWallet(walletAddressInput) && checkData({ ...configs });
  }, [walletAddressInput]);

  return (
    <>
      <div className="w-full">
        <div
          className={` pb-4 border-b border-black-line w-full ${
            notification?.notificationId !== undefined && "hidden"
          }`}
        >
          <h3>{t("notifications.liquid_staking", { ns: "common" })}</h3>
        </div>

        <select
          multiple={false}
          value={scope}
          onChange={handleScope}
          disabled={notification?.notificationId !== undefined}
          className=" focus:ring-0 focus:outline-none  bg-white mt-4"
        >
          <option value="">
            {t(`notifications.select_scope`, { ns: "common" })}
          </option>
          {Object.values(ScopeNotification)
            .filter(
              (value) =>
                value === ScopeNotification.myWallet ||
                value === ScopeNotification.otherWallet
            )
            .map((scope: string) => (
              <option
                className="bg-white text-gray-darksm"
                value={scope}
                key={Math.random() * 0.5}
              >
                {t(`scope_notifications.liquid_staking.${scope}`, {
                  ns: "enumerations",
                })}
              </option>
            ))}
        </select>
      </div>
      {scope === ScopeNotification.myWallet && (
        <div className="mt-6">
          <>
            <p className="">
              {t("notifications.choose_wallet", {
                ns: "common",
              })}
            </p>
            <select
              multiple={false}
              disabled={
                countUsersWallets(userData) === 1 ||
                notification?.notificationId !== undefined
              }
              value={
                notification?.notificationId !== undefined
                  ? notification.walletAddress
                  : walletAddressInput
              }
              className={`focus:border-0 myWallet focus:ring-0 focus:outline-none ${
                countUsersWallets(userData) === 1 && " bg-gray-extralight20"
              }`}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                checkWallet(e.target.value);
              }}
            >
              {userData?.wallets.map((w) => (
                <option
                  value={w.walletAddress}
                  disabled={notification?.notificationId !== undefined}
                >
                  {w.walletAlias || w.walletAddress}
                </option>
              ))}
            </select>
            <div
              className={`my-8 ${
                notification?.notificationId !== undefined && "hidden"
              }`}
            ></div>
          </>
        </div>
      )}

      {((notification?.notificationId === undefined &&
        notification?.scope === ScopeNotification.otherWallet) ||
        scope === ScopeNotification.otherWallet) && (
        <div className="mt-6">
          <p className="">
            {t(`notifications.walletAddress`, { ns: "common" })}
          </p>
          <input
            value={
              notification?.notificationId !== undefined
                ? notification.walletAddress
                : walletAddressInput
            }
            onBlur={() => checkWallet(walletAddressInput)}
            name="wallet"
            onChange={handleWalletChanged}
            type="text"
            placeholder={t(`notifications.address`, { ns: "common" })}
            className={`focus:ring-0  focus:border-gray-extralight90medium focus:outline-none rounded w-full text-base h-12 p-3 border-gray-extralight90medium text-black-transparent50`}
          />
          {notification?.notificationId === undefined && (
            <small className="px-1 inputError hidden text-red">
              {t("notifications.invalid_wallet", { ns: "common" })}
            </small>
          )}
        </div>
      )}
      <div className="mt-6">
        <p className="text-gray-dark font-semibold">
          {t("notifications.domain_options")}
        </p>

        <div className="flex items-start mb-4 px-4">
          <div className="sm:w-5/12 ">
            {Object.keys(LiquidStakingOptions).map((optStaking: string) => (
              <label
                htmlFor={optStaking}
                className="cursor-pointer flex items-center my-7"
              >
                <input
                  className={`mx-2 focus:outline-none focus:ring-0 text-gold border-gold checked:border-0 focus:ring-transparent cursor-pointer transition-colors ${
                    (!scope ||
                      (walletAddressInput === "" &&
                        notification === undefined)) &&
                    "bg-gray-extralight20"
                  } `}
                  type="checkbox"
                  name={optStaking}
                  id={optStaking}
                  disabled={
                    !scope ||
                    (walletAddressInput === "" && notification === undefined)
                  }
                  checked={
                    notification !== undefined && configs
                      ? returnObjectByKey(configs, optStaking)?.[1]?.flag
                      : undefined
                  }
                  onChange={handleLiquidStakingOptions}
                />

                <span className="text-sm">
                  {t(`liquid_staking_options.${optStaking}`, {
                    ns: "enumerations",
                  })}
                </span>
                {(optStaking === LiquidStakingOptions.finishUnbond ||
                  optStaking === LiquidStakingOptions.submitBatch) && (
                  <span className="text-gold ml-0.5 inline-block">!</span>
                )}
              </label>
            ))}
          </div>
          <div className="sm:w-5/12 mx-1 ">
            {Object.values(LiquidStakingOptions).map(
              (typePrice: string, index: number) => (
                <select
                  multiple={false}
                  key={index}
                  className={`focus:ring-0 selectTypes my-3 py-1 focus:outline-none customScrollbar ${
                    !scope && "bg-gray-extralight20"
                  } `}
                  value={
                    notification !== undefined && configs
                      ? returnObjectByKey(configs, typePrice)?.[1]?.type
                      : undefined
                  }
                  name={typePrice}
                  onChange={(e) => handleTypeAndPriceStaking(e)}
                  disabled={
                    !scope ||
                    (configs && !returnObjectByKey(configs, typePrice)[1]?.flag)
                  }
                >
                  {priceTypes?.map((tp, idx: number) => {
                    return (
                      <option value={tp.type} key={index + idx + 1}>
                        {t(`alert_configuration_settings.${tp.type}`, {
                          ns: "common",
                        })}
                      </option>
                    );
                  })}
                </select>
              )
            )}
          </div>
          <div className="sm:w-2/12 sm:mt-0 mx-1 ">
            <div className="justify-end block items-center">
              {Object.values(LiquidStakingOptions).map(
                (price: string, index: number) => (
                  <input
                    key={index}
                    className={`my-3 height42 focus:outline-none text-center inline-block w-14 sm:w-20 focus:ring-0 checked:border-0 focus:ring-transparent cursor-pointer border-gray-semidark transition-colors ${
                      (!scope || walletAddressInput === "") &&
                      "bg-gray-extralight20"
                    }`}
                    type="number"
                    name={price}
                    placeholder={" 0 "}
                    id={price}
                    min={0}
                    disabled={
                      !scope ||
                      !(configs && returnObjectByKey(configs, price)[1]?.flag)
                    }
                    value={
                      (configs &&
                        returnObjectByKey(configs, price)[1]?.value !==
                          99999999 &&
                        returnObjectByKey(configs, price)[1]?.value) ||
                      0
                    }
                    onChange={handleTypeAndPriceStaking}
                  />
                )
              )}
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="py-4 px-2 shadow-sm bg-gold10 rounded flex ">
            <div className="flex grow-0 text-white relative items-start justify-start -ml-2 mr-2">
              <Balloon
                disabled={true}
                type={BalloonType.info}
                id="connect-info"
                noFlex={true}
                noEffectHover={true}
              >
                <span>!</span>
              </Balloon>
            </div>
            <div className="text-sm">
              <div className="mt-1 mb-2">
                {t("notifications.disclaimer", {
                  ns: "common",
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiquidStaking;
