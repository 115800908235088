import { faChevronDown, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyButton } from "components/CopyButton";
import { Tooltip } from "components/Tooltip";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const MfaEnabling: React.FC<{
  qrCode: string;
  tempUserData: any;
  setToken: Dispatch<SetStateAction<any>>;
}> = ({ qrCode, tempUserData, setToken }) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const [copy, setCopy] = useState(false);
  const [showMfaCode, setShowMfaCode] = useState(false);

  const copyToClipboard = (word?: string) => {
    navigator.clipboard.writeText(word || "");
    setCopy(true);
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }
  }, [copy]);

  return (
    <>
      <div className="text-center mb-8">
        <h2 className="text-xl my-4">
          {t("modal_content.mfa.modal_title", {
            ns: "enumerations",
          })}
        </h2>
        <h3 className="text-lg font-normal w-11/12 md:w-8/12 text-center m-auto">
          {t("modal_content.mfa.additional_title", {
            ns: "enumerations",
          })}
          .{" "}
          <a
            href="https://docs.hermesprotocol.io/docs/webapp/2FA/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {t("modal_content.mfa.learn_more", {
              ns: "enumerations",
            })}
          </a>
        </h3>
      </div>
      <span className="text-base">{t(`mfa.step1`, { ns: "common" })}</span>

      <img
        src={qrCode}
        alt="mfa"
        onClick={() => copyToClipboard(tempUserData.temp_secret.base32)}
        className="w-32 pb-2"
        title="Copy code"
      />
      <span
        className="hover:underline cursor-pointer text-sm"
        onClick={() => setShowMfaCode(!showMfaCode)}
      >
        {t(`mfa.alternative_step`, { ns: "common" })}
        <FontAwesomeIcon
          icon={faChevronDown}
          size="xs"
          className="text-gray-dark ml-2"
        />
      </span>
      {showMfaCode && (
        <div
          className="flex items-center relative w-3/4 cursor-pointer"
          onClick={() => copyToClipboard(tempUserData.temp_secret.base32)}
        >
          <input
            className="cursor-pointer appearance-none outline-none focus:outline-none border text-xs rounded py-2 border-gray-extralight90medium text-black-transparent50 w-full text-center"
            readOnly
            value={tempUserData.temp_secret.base32}
          />
          <CopyButton
                hoverText={ t("notifications.copy", "common")}
                clickText={t("notifications.copied", "common")}
                isCopying={copy}
                onCopy={copyToClipboard}
                copiedElement={tempUserData.temp_secret.base32}
              />
        </div>
      )}
      <span className="mt-6 text-base mb-2"> {t(`mfa.step2`, { ns: "common" })}</span>
      <input
        onChange={(e) => setToken(e.target.value)}
        className="appearance-none outline-none focus:outline-none border text-lg rounded py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-black-transparent50 w-32 text-center"
        maxLength={6}
      />
    </>
  );
};
